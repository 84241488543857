import * as React from 'react';
import { useState } from 'react';

/** Material UI Packages. */
import { Fab } from '@mui/material';

/** Material UI Icons. */
import RvHookupIcon from '@mui/icons-material/RvHookup';

/** Internal Components. */
import ChassisDialog from './ChassisDialog';
import CustomSnackbar from '../../../CustomSnackbar';

export default function ChassisStatus(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from Schedule.js.  */
    const { dispatch, chassis, chassisLocation, setChassisLocation } = props;

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** Dialog open state using Fab component. */
    const [chassisOpen, setChassisOpen] = useState(false);

    /** Handler to open dialog. */
    const handleFabClick = () => {
        setChassisOpen(true);
    };

    return (
        <>
            <Fab
                size="medium"
                color="primary"
                aria-label="add"
                sx={{ position: 'absolute', bottom: 75, right: 16 }}
                onClick={handleFabClick}
            >
                <RvHookupIcon />
            </Fab>
            <ChassisDialog
                userId={userId}
                dispatch={dispatch}
                chassis={chassis}
                chassisLocation={chassisLocation}
                setChassisLocation={setChassisLocation}
                chassisOpen={chassisOpen}
                setChassisOpen={setChassisOpen}
                setSnackbarOpen={setSnackbarOpen}
                setSnackMsg={setSnackMsg}
                setSnackSeverity={setSnackSeverity}
            />
            <CustomSnackbar
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                snackMsg={snackMsg}
                snackSeverity={snackSeverity}
            />
        </>
    );
}
