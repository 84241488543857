import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Typography, Stack, Paper, Grid, Button } from '@mui/material';
import { DataGridPremium, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';

/** Material UI Icons. */
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

/** Internal Components. */
import useApiRequest from '../../useApiRequest';
import AutoCompleteSelect from './AutoCompleteSelect';
import { customized_style } from '../../../theme/Styles';

/**
 * Add a new row for additional charge detail datagrid.
 * @param {*} props
 * @returns newRows
 */
function EditToolbar(props) {
    const { setAdditionalChargeRow, setSaveBtnDisable, editable } = props;
    const handleClick = () => {
        const id = randomId();
        setAdditionalChargeRow((oldRows) => [
            ...oldRows,
            {
                id,
                charge_type: null,
                addcharge_rate: null,
                qty: 1,
                total_rate: null,
                addcharge_note: null,
                profile_id: null,
                isNew: true,
            },
        ]);
        setSaveBtnDisable(false);
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled={!editable}>
                ADD CHARGE
            </Button>
        </GridToolbarContainer>
    );
}

export function ExtraChargeList(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from ManifestAssigned.js.  */
    const { addCharges, setSnackbarOpen, setSnackSeverity, setSnackMsg } = props;
    /** Get props from ManifestAssigned.js.  */
    const { rowProp } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Date format for datagrid. */
    const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };

    /** State for additional charge detail datagrid Row. */
    const [additionalChargeRow, setAdditionalChargeRow] = useState([]);
    /** State for managing deleted row's movement_id. */
    const [lastDel, setLastDel] = useState([]);
    /** State for the rate data applied based on the move date.  */
    const [chargeList, setChargeList] = useState([]);
    /** State to store the enable/disable status of the save button based on changes in the datagrid. */
    const [saveBtnDisable, setSaveBtnDisable] = useState(true);
    /**  */
    const [editable, setEditable] = useState(false);
    const [editableRate, setEditableRate] = useState(false);

    /** Selected movement ID */
    const [selectedId, setSelectedId] = useState(null);
    /** Rate Profile ID */
    const [profileId, setProfileId] = useState(null);

    /** Initiallize additional charge datagrid row. */
    useEffect(() => {
        const addCharge = rowProp.addCharge;
        setAdditionalChargeRow(addCharge);
    }, []);

    /**  */
    useEffect(() => {
        setSelectedId(rowProp?.id); // Same as movement_id.
        let profile_found = false;
        const movement_date = new Date(rowProp.move_date).toLocaleDateString('en-US', date_format);
        const driver_info = rowProp.driver_id;
        //
        [...addCharges].reverse().some((item) => {
            const profile_date = new Date(item.date_applied).toLocaleDateString('en-US', date_format);
            if (profile_date <= movement_date && item.drivers.includes(driver_info)) {
                setChargeList(item.charges);
                setProfileId(item.profile_id);
                profile_found = true;
                return true;
            }
            return false;
        });
    }, [rowProp, addCharges]);

    /** Handle to delete datagrid row. */
    const handleDeleteClick = (id) => () => {
        if (additionalChargeRow.length === 1) {
            setLastDel([additionalChargeRow[0].movement_id]);
        }
        setAdditionalChargeRow(additionalChargeRow.filter((row) => row.id !== id));
        setSaveBtnDisable(false);
    };

    /** Handle to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, movement_id: selectedId, profile_id: profileId, isNew: false }; // movement_id, profile_id are used for backend function.
        setAdditionalChargeRow(additionalChargeRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setSaveBtnDisable(false);
        return updatedRow;
    };

    /** Handle to save addtional charge datagrid to DB. */
    const handleSaveBtn = () => {
        const saveData = additionalChargeRow.length > 0 ? additionalChargeRow : lastDel;
        apiRequest('manifest', 'save', userId, saveData)
            .then((response) => {
                setSnackbarOpen(true);
                setSnackSeverity(response.statusCode === 200 ? 'success' : 'error');
                setSnackMsg(response.body.message);
            })
            .catch(() => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });

        setSaveBtnDisable(true);
    };

    /** */
    useEffect(() => {
        setEditable(!rowProp.payment_status);
        setEditableRate(false);
    }, [rowProp]);

    /** Define additional charge Datagrid Fields. */
    const addtionalCharge_col = [
        {
            field: 'charge_type',
            headerName: 'Charge Type',
            type: 'singleSelect',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            editable: editable,
            renderEditCell: (params) => {
                return <AutoCompleteSelect params={{ ...params }} arryOption={chargeList} />;
            },
            valueFormatter: (params) => chargeList.find((item) => item.id === params.value)?.name || '',
        },
        {
            field: 'addcharge_rate',
            headerName: 'Rate',
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            valueGetter: (params) => {
                const charge = [...chargeList].find((item) => item?.id === params?.row?.charge_type); //charge_type information.
                const charge_detail = [...additionalChargeRow] // Find the row information.
                    .find((item) => item.id === params.row.id);
                const chargeName = charge?.name;
                if (chargeName !== undefined && chargeName !== 'Others') {
                    setEditableRate(false);
                    if (['Bobtail Start - 1st Yard', 'Bobtail Start - 2nd Yard', 'Overweight'].includes(chargeName)) {
                        if (charge_detail.charge_type !== params.row.charge_type) {
                            return charge?.rate;
                        }
                        return params.row.addcharge_rate;
                    }
                    const result = charge?.rate;
                    params.row.addcharge_rate = result;
                    return result;
                } else if (chargeName !== undefined && chargeName === 'Others') {
                    setEditableRate(true);
                    const result = params.row.addcharge_rate;
                    return result;
                }
            },
            editable: editableRate,
        },
        {
            field: 'qty',
            headerName: 'QTY',
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            editable: editable,
            valueGetter: (params) => (params.value <= 0 ? 1 : params.value),
        },
        {
            field: 'total_rate',
            headerName: 'Total',
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            editable: false,
            valueGetter: (params) => {
                const charge = [...chargeList].find((item) => item.id === params.row.charge_type);
                const chargeName = charge?.name;
                const chargeRate = charge?.rate;
                const result = chargeRate * parseInt(params.row.qty);
                if (chargeName !== undefined && chargeName !== 'Others') {
                    if (isNaN(result)) {
                        return 0;
                    } else {
                        return result;
                    }
                } else if (chargeName !== undefined && chargeName === 'Others') {
                    const othersRate = parseInt(params.row.addcharge_rate);
                    const other_result = othersRate * parseInt(params.row.qty);
                    if (isNaN(other_result)) {
                        return 0;
                    } else {
                        return other_result;
                    }
                }
            },
        },
        {
            field: 'addcharge_note',
            headerName: 'Note',
            align: 'center',
            headerAlign: 'center',
            flex: 2,
            editable: editable,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                        disabled={!editable}
                    />,
                ];
            },
        },
    ];

    return (
        <Stack sx={{ py: 3, height: '100%', boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '92%', p: 1, pb: 2 }}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h6" color="textSecondary">
                        Additional Charge Detail
                    </Typography>
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography variant="body2">Name : {rowProp.driver}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">Container# : {rowProp.cntr}</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                sx={{ ...customized_style.itemButtonRound, mb: 0, mr: 1 }}
                                disabled={saveBtnDisable}
                                onClick={handleSaveBtn}
                            >
                                SAVE
                            </Button>
                        </Grid>
                    </Grid>
                    <DataGridPremium
                        editMode="row"
                        rows={additionalChargeRow || []}
                        columns={addtionalCharge_col}
                        density="compact"
                        hideFooter
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        processRowUpdate={processRowUpdate}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        slotProps={{
                            toolbar: { setAdditionalChargeRow, setSaveBtnDisable, editable },
                        }}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                border: '1px solid #e9ecef',
                            },
                        }}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}
