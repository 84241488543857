import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

/** Material UI Packages. */
import { Box, CircularProgress } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';
import ManifestSearch from './ManifestSearch';
import { ExtraChargeList } from './ExtraChargeList';
import { CustomExportToolbar } from '../../CustomExport';

import dayjs from 'dayjs';

export default function ManifestAssigned(props) {
    /** Get props from App.js. */
    const { userId } = props;
    /** Get props from Manifest.js. */
    const {
        addCharges,
        inprogressRow,
        setInprogressRow,
        completeRow,
        setCompleteRow,
        active,
        count,
        loadedInprogress,
        loadedComplete,
    } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** Date format for datagrid. */
    const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };

    /** Callback to get the detail panel content for rows. */
    const getDetailPanelContent = useCallback(
        ({ row }) => (
            <ExtraChargeList
                userId={userId}
                rowProp={row}
                addCharges={addCharges}
                setSnackbarOpen={setSnackbarOpen}
                setSnackSeverity={setSnackSeverity}
                setSnackMsg={setSnackMsg}
            />
        ),
        []
    );

    /** Callback to get the height for detail panel. */
    const getDetailPanelHeight = useCallback(() => 'auto', []);

    /** Effect to set move date format for datagrid based on active state. */
    useEffect(() => {
        const updateRowDates = (rows) => rows.map((item) => ({ ...item, move_date: dayjs(item.move_date) }));
        if (active) {
            setInprogressRow(updateRowDates(inprogressRow));
        } else {
            setCompleteRow(updateRowDates(completeRow));
        }
    }, [count]);

    /** Define movements datagrid Fields. */
    const movements_col = [
        { field: 'id', headerName: 'ID', flex: 0.3, align: 'center', headerAlign: 'center' },
        {
            field: 'move_date',
            headerName: 'Move Date',
            type: 'date',
            flex: 0.7,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        {
            field: 'cntr',
            headerName: 'Container#',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'size',
            headerName: 'Size',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pickup',
            headerName: 'Pick Up',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'delivery',
            headerName: 'Delivery',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'driver',
            headerName: 'Driver',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'method',
            headerName: 'Method',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'chassis',
            headerName: 'Chassis',
            flex: 0.7,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.7,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'payment_status',
            headerName: 'Confirmed',
            flex: 0.5,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
            editable: true,
            headerClassName: 'editable-cell',
        },
    ];

    /**
     * Check if status field is editable.
     * Cannot edit the confirmed column when the movement status is "In Progress".
     */
    const isStatusEditable = (params) => {
        if (params.row.status == 'In Progress') {
            return false;
        } else return true;
    };

    /** Handler to process row updates. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        /** POST request to DB */
        apiRequest('manifest', 'status', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });

        /** Update the datagrid row in the state. */
        if (active) {
            setInprogressRow(inprogressRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        } else {
            setCompleteRow(completeRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        }
        return updatedRow;
    };

    return (
        <>
            <Box
                sx={{
                    height: active ? 630 : 520,
                    '& .editable-cell': {
                        color: '#e36414',
                    },
                }}
            >
                {active ? (
                    <></>
                ) : (
                    <ManifestSearch
                        userId={userId}
                        inprogressRow={inprogressRow}
                        setInprogressRow={setInprogressRow}
                        completeRow={completeRow}
                        setCompleteRow={setCompleteRow}
                        active={active}
                    />
                )}
                {loadedInprogress || loadedComplete ? (
                    <DataGridPremium
                        rows={active ? inprogressRow : completeRow}
                        columns={movements_col}
                        density="compact"
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        processRowUpdate={processRowUpdate}
                        slots={{
                            toolbar: CustomExportToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                        sx={{ border: 'none', fontSize: '0.8rem' }}
                        getDetailPanelHeight={getDetailPanelHeight}
                        getDetailPanelContent={getDetailPanelContent}
                        isCellEditable={(params) => isStatusEditable(params)}
                    />
                ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                        <CircularProgress />
                    </Box>
                )}
                <CustomSnackbar
                    snackbarOpen={snackbarOpen}
                    setSnackbarOpen={setSnackbarOpen}
                    snackMsg={snackMsg}
                    snackSeverity={snackSeverity}
                />
            </Box>
        </>
    );
}
