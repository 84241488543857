import * as React from 'react';
import { useState } from 'react';

/** Material UI Packages. */
import { Dialog, Box, AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material';
import { GridRowModes, DataGridPremium, GridRowEditStopReasons, GridToolbarContainer } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { randomId } from '@mui/x-data-grid-generator';

/** Material UI Icons. */
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

/** Internal Components. */
import useApiRequest from '../../../useApiRequest';

function EditToolbar(props) {
    const { setMemoRow, setRowModesModel } = props;

    const handleClick = () => {
        const id = randomId();
        setMemoRow((oldRows) => [...oldRows, { id, create_on: '', memo: '', create_by: '', isNew: true }]);
        setRowModesModel((oldModel) => ({
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'memo' },
            ...oldModel,
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add Memo
            </Button>
        </GridToolbarContainer>
    );
}

export default function MemoDialog(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from MemoFab.js.  */
    const { memoRow, setMemoRow, memoOpen, setMemoOpen, setSnackbarOpen, setSnackSeverity, setSnackMsg } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Object with active row on datagrid. */
    const [rowModesModel, setRowModesModel] = useState({});

    /** Define Memo Datagrid Fields. */
    const memo_col = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center', hide: true },
        {
            field: 'create_date',
            headerName: 'Create On',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'memo',
            headerName: 'Memo',
            flex: 5,
            editable: true,
            headerAlign: 'center',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 0.8,
            align: 'center',
            headerAlign: 'center',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="primary.main"
                    />,
                ];
            },
        },
    ];

    /** Deactivate edit row mode on Datagrid. */
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    /** Datagrid Row edit handler for memo. */
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    /** Datagrid Row save handler for memo. */
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    /** Datagrid Row delete handler for memo. */
    const handleDeleteClick = (id) => () => {
        if (window.confirm('Are you sure you want to delete the entry?')) {
            const deleted_obj = memoRow.find((row) => row.id === id).id;
            /** POST request to DB */
            apiRequest('schedule', 'memo-delete', userId, [deleted_obj])
                .then((response) => {
                    if (response.statusCode === 200) {
                        setSnackbarOpen(true);
                        setSnackSeverity('success');
                        setSnackMsg(response.body.message);
                    } else {
                        setSnackbarOpen(true);
                        setSnackSeverity('error');
                        setSnackMsg(response.body.message);
                    }
                })
                .catch((error) => {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg('Something went wrong. Please try again.');
                });
            setMemoRow(memoRow.filter((row) => row.id !== id));
        }
    };

    /** rowModesModel state change to View mode. */
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = memoRow.find((row) => row.id === id);
        if (editedRow.isNew) {
            setMemoRow(memoRow.filter((row) => row.id !== id));
        }
    };

    /** When double click, activate Row to change. */
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    /** Handler to remind save and close a dialog for chassis status. */
    const handleClickClose = () => {
        setMemoOpen(false);
    };

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow };
        setMemoRow(memoRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        apiRequest('schedule', 'memo-write', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setMemoRow(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });

        return updatedRow;
    };

    return (
        <>
            <Dialog maxWidth="md" fullWidth={true} open={memoOpen} onClose={handleClickClose}>
                <Box sx={{ height: 620 }}>
                    <AppBar sx={{ bgcolor: '#00647e', position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClickClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, fontWeight: 'bold' }} variant="h6">
                                Private Memo
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DataGridPremium
                        rows={memoRow}
                        columns={memo_col}
                        editMode="row"
                        processRowUpdate={processRowUpdate}
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        sx={{
                            height: 550,
                            '& .MuiDataGrid-cell': {
                                border: '1px solid #e9ecef',
                            },
                        }}
                        getRowHeight={() => 'auto'}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        slotProps={{
                            toolbar: { setMemoRow, setRowModesModel },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                    />
                </Box>
            </Dialog>
        </>
    );
}
