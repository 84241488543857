import * as React from 'react';
import { useState, forwardRef, useEffect } from 'react';

/** Material UI Packages. */
import {
    Button,
    Slide,
    Dialog,
    Grid,
    DialogTitle,
    DialogContent,
    Checkbox,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    DialogActions,
} from '@mui/material';

/** Dialog showing animation.  */
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/** Handler to filter only unchecked listItem. */
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}
/** Handler to filter checked listItem. */
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function RateDriverList(props) {
    /* Get Dialog props from Rate.js */
    const {
        tabContent,
        tabValue,
        saveList,
        setSaveList,
        left,
        setLeft,
        right,
        setRight,
        setSaveBtnDisable,
        allDriverList,
        driver,
    } = props;
    /* Get Dialog props from RateContent.js */
    const { rateDriverListDialogOpen, setRateDriverListDialogOpen } = props;

    /** Transfer List states. */
    const [checked, setChecked] = useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    /** When click close button, change dialog state value. */
    const handleRateDriverListDialogClose = () => {
        const unassigned_driver = [...saveList].map((item) => item.name).flat();
        const available = allDriverList.filter((value) => !unassigned_driver.includes(value));
        setLeft(available);
        const assigned_drvier = [...saveList]
            .map((item) => {
                if (item.id === tabContent[tabValue].id) return item.name;
            })
            .flat()
            .filter((item) => item != undefined);
        {
            assigned_drvier !== null ? setRight(assigned_drvier) : setRight([]);
        }
        setRateDriverListDialogOpen(false);
    };

    /** Handler to save transfer list. */
    const handleRateDriverListChange = () => {
        const assignedDriver = { id: tabContent[tabValue].id, name: right };
        const otherDriver = [...saveList].filter((item) => item.id !== assignedDriver.id);
        const finalList = [...otherDriver, assignedDriver];
        setSaveList(finalList);
        setLeft(left); //Update left array to avoid duplication.
        setSaveBtnDisable(false);
        setRateDriverListDialogOpen(false);
    };

    /** Handle to change checkbox state.  */
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    /** All left listItems move to right list. */
    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    /** Some left listItems move to right list. */
    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    /** Some right listItems move to left list. */
    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    /** All right listItems move to left list. */
    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    /**
     * Filtered assingned driver list from Left side.
     * When change saveList state, right-side list will be updated to new_tabRight.
     */
    useEffect(() => {
        const assigned_drivers = [...saveList].map((item) => item.name).flat();
        const available_drivers = [...allDriverList].filter((val) => !assigned_drivers.includes(val));
        setLeft(available_drivers);

        const new_tabRight = [...saveList]
            .filter((item) => item.id === tabContent[tabValue].id)
            .map((item) => item.name)
            .flat();
        setRight(new_tabRight);
    }, [saveList]);

    /** Showing all registered drivers in transfer list(both). */
    const assignedDriverList = (items) => {
        return (
            <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
                <List dense component="div" role="list">
                    {items.map((value) => {
                        const labelId = `transfer-list-item-${value}-label`;
                        return (
                            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value} />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
        );
    };

    return (
        <Dialog
            open={rateDriverListDialogOpen}
            onClose={handleRateDriverListDialogClose}
            TransitionComponent={Transition}
        >
            <DialogTitle sx={{ color: '#00657e', fontSize: '16px' }}>Driver Assignment</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>{assignedDriverList(left)}</Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllRight}
                                disabled={left.length === 0}
                                aria-label="move all right"
                            >
                                ≫
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllLeft}
                                disabled={right.length === 0}
                                aria-label="move all left"
                            >
                                ≪
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>{assignedDriverList(right)}</Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRateDriverListDialogClose}>Close</Button>
                <Button onClick={handleRateDriverListChange}>Change</Button>
            </DialogActions>
        </Dialog>
    );
}
