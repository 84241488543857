import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Stack } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Internal Components. */
import { usdPrice } from '../../CustomFormat';

export default function PayrollAddChargeSub({ row: rowProp }) {
    /** Additional charge detail data grid Row. */
    const [addChargeDetailRow, setAddChargeDetailRow] = useState([]);

    /** Initialize Payroll detail data grid Row. */
    useEffect(() => {
        setAddChargeDetailRow(rowProp.add_rate_detail);
    }, [rowProp]);

    /** Columns configuration for the additonal charge detail DataGrid. */
    const addChargeDetail_col = [
        {
            field: 'type',
            headerName: 'Type',
            align: 'center',
            headerAlign: 'center',
            width: 200,
            sortable: false,
        },
        {
            field: 'rate',
            headerName: 'Rate',
            align: 'center',
            headerAlign: 'center',
            width: 120,
            sortable: false,
            ...usdPrice,
        },
        { field: 'qty', headerName: 'QTY', align: 'center', headerAlign: 'center', width: 140, sortable: false },
        {
            field: 'total',
            headerName: 'Total',
            align: 'center',
            headerAlign: 'center',
            width: 140,
            sortable: false,
            ...usdPrice,
        },
        {
            field: 'note',
            headerName: 'Note',
            align: 'center',
            headerAlign: 'center',
            width: 200,
            sortable: false,
        },
    ];
    return (
        <Stack sx={{ height: '100%', boxSizing: 'border-box' }} direction="column">
            <DataGridPremium
                rows={addChargeDetailRow || []}
                columns={addChargeDetail_col}
                density="compact"
                hideFooter
                disableColumnMenu
                disableRowSelectionOnClick
                sx={{ fontSize: '0.8rem', border: 'none', bgcolor: '#f1f1f1' }}
            />
        </Stack>
    );
}
