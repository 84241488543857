import * as React from 'react';

/** Material UI Packages. */
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';

export const CustomExportToolbar = () => {
    return (
        <>
            <GridToolbarContainer style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport
                        csvOptions={{ disableToolbarButton: false }}
                        printOptions={{ disableToolbarButton: false }}
                        excelOptions={{ disableToolbarButton: true }}
                    />
                </div>
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        </>
    );
};

export const CustomExportToolbar2 = () => {
    return (
        <>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport
                    csvOptions={{ disableToolbarButton: false }}
                    printOptions={{ disableToolbarButton: false }}
                    excelOptions={{ disableToolbarButton: true }}
                />
            </GridToolbarContainer>
        </>
    );
};
