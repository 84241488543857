import * as React from 'react';
import { useState } from 'react';

/** Material UI Packages. */
import { Fab, Dialog } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

/** Material UI Icons. */
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function TimelineCalendar(props) {
    /** Get props from Timeline.js.  */
    const { selectedDate, setSelectedDate, saveBtnDisable } = props;

    /** Dialog open state using Fab component. */
    const [calendarOpen, setCalendarOpen] = useState(false);

    /** Handler to open dialog. If save button is acitivated, can't move other Date. */
    const handleFabClick = () => {
        {
            saveBtnDisable == false
                ? window.alert('Please save a timeline first before you move the page.')
                : setCalendarOpen(true);
        }
    };

    /** Change selected date value and close dialog. */
    const handleDateChange = (val) => {
        setSelectedDate(val);
        setCalendarOpen(false);
    };
    return (
        <>
            {/* Movement Date set up using float button action. */}
            <Fab
                size="medium"
                color="primary"
                aria-label="add"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                onClick={handleFabClick}
            >
                <CalendarMonthIcon />
            </Fab>

            <Dialog open={calendarOpen} onClose={() => setCalendarOpen(false)}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                </LocalizationProvider>
            </Dialog>
        </>
    );
}
