import * as React from 'react';

/** Material UI Packages. */
import { Skeleton } from '@mui/material';

export function CustomSkeleton() {
    return (
        <>
            <Skeleton animation="wave" height="20%" />
            <Skeleton animation="wave" height="20%" />
            <Skeleton animation="wave" height="10%" />
            <Skeleton animation="wave" height="10%" />
            <Skeleton animation="wave" height="5%" />
            <Skeleton animation="wave" height="5%" />
            <Skeleton animation="wave" height="5%" />
        </>
    );
}
export function CustomSkeletonVer2() {
    return (
        <>
            <Skeleton animation="wave" height="100px" />
            <Skeleton animation="wave" height="90px" />
            <Skeleton animation="wave" height="80px" />
            <Skeleton animation="wave" height="70px" />
            <Skeleton animation="wave" height="60px" />
            <Skeleton animation="wave" height="50px" />
            <Skeleton animation="wave" height="40px" />
            <Skeleton animation="wave" height="30px" />
            <Skeleton animation="wave" height="30px" />
            <Skeleton animation="wave" height="20px" />
            <Skeleton animation="wave" height="20px" />
            <Skeleton animation="wave" height="10px" />
            <Skeleton animation="wave" height="10px" />
            <Skeleton animation="wave" height="10px" />
        </>
    );
}
