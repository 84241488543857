/**
 * Define the columns for the load schedule table.
 * Type: loadOrder
 *   */
export const loadColumns = [
    { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center' },
    { field: 'team', headerName: 'Team', flex: 0.5, align: 'center', headerAlign: 'center' },
    { field: 'client', headerName: 'Client', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'mbl', headerName: 'Booking# / MBL#', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'cntr', headerName: 'CNTR#', flex: 1, align: 'center', headerAlign: 'center' },
    { field: 'size', headerName: 'Size', flex: 0.5, align: 'center', headerAlign: 'center' },
    { field: 'terminal', headerName: 'Terminal', flex: 1, align: 'center', headerAlign: 'center' },
    {
        field: 'dem',
        type: 'date',
        headerName: 'DEM.LFD',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) =>
            params.value
                ? new Date(params.value).toLocaleDateString('en-US', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                  })
                : null,
    },
    {
        field: 'appt',
        headerName: 'APPT',
        flex: 1,
        type: 'date',
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) =>
            params.value
                ? new Date(params.value).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                  })
                : null,
    },
    {
        field: 'aim_chassis',
        headerName: 'AIM Chassis',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'remark',
        headerName: 'Remark',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
];

/**
 * Define the columns for the empty schedule table.
 * Type: emptyOrder
 *   */
export const emptyColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 50,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'team',
        headerName: 'Team',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'client',
        headerName: 'Client',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'mbl',
        headerName: 'Booking# / MBL#',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'cntr',
        headerName: 'CNTR#',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'size',
        headerName: 'Size',
        flex: 0.5,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'terminal',
        headerName: 'Terminal',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'pdiem',
        headerName: 'P.DIEM.LFD',
        type: 'date',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        headerClassName: 'editable-cell',
        valueFormatter: (params) =>
            params.value
                ? new Date(params.value).toLocaleDateString('en-US', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                  })
                : null,
    },
    {
        field: 'empty_date',
        headerName: 'Empty Date',
        type: 'date',
        width: 100,
        align: 'center',
        headerAlign: 'center',
        headerClassName: 'editable-cell',
        valueFormatter: (params) =>
            params.value
                ? new Date(params.value).toLocaleDateString('en-US', {
                      year: '2-digit',
                      month: '2-digit',
                      day: '2-digit',
                  })
                : null,
    },
    {
        field: 'aim_chassis',
        headerName: 'AIM Chassis',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'remark',
        headerName: 'Remark',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
    },
];
