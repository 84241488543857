import * as React from 'react';
import { useState } from 'react';

/** Material UI Packages. */
import { Box, Grid, Typography, Paper, Divider, Avatar, IconButton } from '@mui/material';

/** Material UI Icons. */
import AutorenewIcon from '@mui/icons-material/Autorenew';

/** Internal Components. */
import grow_up_icon from '../../../../statics/icons/grow-up.png';
import news_report_icon from '../../../../statics/icons/news-report.png';
import organization_icon from '../../../../statics/icons/organization.png';
import { dashboard_style } from '../../../theme/Styles';
import useApiRequest from '../../useApiRequest';

const CustomCount = ({
    src,
    header,
    number,
    detail,
    handle_method,
    parameter,
    setSnackMsg,
    setSnackSeverity,
    setSnackbarOpen,
}) => {
    /** Custom API Request function */
    const apiRequest = useApiRequest();

    const handleUpdate = (parameter) => {
        apiRequest('dashboard', parameter, 1, [])
            .then((response) => {
                handle_method(response.body.data);
                setSnackbarOpen(true);
                setSnackMsg(response.body.message);
                setSnackSeverity('success');
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackMsg('Oops, something went wrong :(');
                setSnackSeverity('error');
            });
    };

    return (
        <>
            <Grid container>
                <Grid item md={4}>
                    <Box sx={{ pt: 3, pb: 2, px: 2 }}>
                        <Avatar alt="chart" src={src} variant="square" sx={{ width: '50px', height: '50px' }} />
                    </Box>
                </Grid>
                <Grid item md={8}>
                    <Box sx={{ pt: 3.3, textAlign: 'right', pr: 2 }}>
                        <Typography sx={{ fontSize: '0.8rem', color: '#a6a2a2' }}>{header}</Typography>
                        <Typography
                            sx={{
                                display: 'inline',
                                fontSize: '1.8rem',
                                fontWeight: 'medium',
                                color: '#847577',
                                lineHeight: 1,
                            }}
                        >
                            {number}
                        </Typography>
                        <Typography
                            sx={{
                                display: ' inline',
                                fontSize: '1.2rem',
                                color: '#847577',
                                fontWeight: 'medium',
                            }}
                        >
                            {detail}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Divider variant="middle" />
            <Box>
                <IconButton onClick={() => handleUpdate(parameter)} sx={{ mx: 1 }}>
                    <AutorenewIcon fontSize="small" sx={{ color: '#747773' }} />
                    <Typography sx={{ display: 'inline', fontSize: '0.8rem', color: '#a6a2a2' }}>Update Now</Typography>
                </IconButton>
            </Box>
        </>
    );
};

export default function DashboardCount(props) {
    /** Get props from Dashboard.js.  */
    const {
        movementCount,
        containerCount,
        setMovementCount,
        setContainerCount,
        setSnackMsg,
        setSnackSeverity,
        setSnackbarOpen,
    } = props;

    /** sample data. */
    const [teamNumber, setTeamNumber] = useState(2);

    const handle_move = (data) => {
        setMovementCount(data);
    };

    const handle_container = (data) => {
        setContainerCount(data);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item md={4}>
                    <Paper sx={dashboard_style.itemPaper_small}>
                        <CustomCount
                            src={grow_up_icon}
                            number={movementCount}
                            header="TODAY'S"
                            detail="MOVE"
                            handle_method={handle_move}
                            parameter={'movement'}
                            setSnackMsg={setSnackMsg}
                            setSnackbarOpen={setSnackbarOpen}
                            setSnackSeverity={setSnackSeverity}
                        />
                    </Paper>
                </Grid>
                <Grid item md={4}>
                    <Paper sx={dashboard_style.itemPaper_small}>
                        <CustomCount
                            src={news_report_icon}
                            number={containerCount}
                            header="IN PROGRESS"
                            detail="CNTR"
                            handle_method={handle_container}
                            parameter={'inprogress'}
                            setSnackMsg={setSnackMsg}
                            setSnackbarOpen={setSnackbarOpen}
                            setSnackSeverity={setSnackSeverity}
                        />
                    </Paper>
                </Grid>
                <Grid item md={4}>
                    <Paper sx={dashboard_style.itemPaper_small}>
                        <CustomCount
                            src={organization_icon}
                            number={teamNumber}
                            header="TEAM"
                            detail="PPL"
                            setSnackMsg={setSnackMsg}
                            setSnackbarOpen={setSnackbarOpen}
                            setSnackSeverity={setSnackSeverity}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
