import * as React from 'react';

/** Formatting to USD currency for amount. */
const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

/** Apply formatting to USD. */
const usdPrice = {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter.format(value),
};

export { usdPrice };
