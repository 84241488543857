import * as React from 'react';
import { useEffect, useState } from 'react';

/** Router Packages. */
import { useNavigate } from 'react-router-dom';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** Internal Components */
import Main from './Main';

import dayjs from 'dayjs';

export default function RenderComponent(props) {
    /** Get props from App.js.  */
    const { userRole, userId } = props;
    /** Get props from App.js.  */
    const { dashboard, deliveryorder, schedule, manifest, dataentry, rate, history, payroll, myprofile, logs } = props;

    /** Get authentication status and user information. */
    const { isAuthenticated, logout } = useAuth0();

    /** Navigate to different routes. */
    const navigate = useNavigate();

    /** Access Token expiry checker. */
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    /**
     * Check token expiry and set token expiry checker to True.
     * @param {Object} isTokenExpired - Token expiry state object.
     */
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        const checkTokenExpiry = () => {
            const tokenExpiry = sessionStorage.getItem('exp');
            if (tokenExpiry && new Date(tokenExpiry) < dayjs()) {
                setIsTokenExpired(true);
            }
        };
        const interval = setInterval(() => {
            checkTokenExpiry();
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    /** Hook to logout once token is expired. */
    useEffect(() => {
        if (isTokenExpired) {
            logout({
                returnTo: window.location.origin + '/login',
            });
        }
    }, [isTokenExpired]);

    /** Effect to handle navigation based on props and userRole. */
    useEffect(() => {
        const handleNavigation = () => {
            switch (true) {
                case dashboard:
                case deliveryorder:
                case schedule:
                case manifest:
                case history:
                    break;
                case dataentry:
                    if (userRole.includes('Staff')) {
                        navigate('/dashboard');
                    } else {
                        navigate('/dataentry');
                    }
                    break;
                case rate:
                    if (userRole.includes('Staff')) {
                        navigate('/dashboard');
                    } else {
                        navigate('/rate');
                    }
                    break;
                case payroll:
                    if (userRole.includes('Staff')) {
                        navigate('/dashboard');
                    } else {
                        navigate('/payroll');
                    }
                    break;
                case logs:
                    if (['Staff', 'Manager'].some((auth) => userRole.includes(auth))) {
                        navigate('/dashboard');
                    } else {
                        navigate('/logs');
                    }
                    break;
                case myprofile:
                    break;
                default:
                    break;
            }
        };
        handleNavigation();
    }, [
        userRole,
        dashboard,
        deliveryorder,
        schedule,
        manifest,
        history,
        dataentry,
        rate,
        payroll,
        logs,
        myprofile,
        isAuthenticated,
    ]);

    return (
        <Main
            userId={userId}
            userRole={userRole}
            dashboard={dashboard}
            deliveryorder={deliveryorder}
            schedule={schedule}
            manifest={manifest}
            history={history}
            dataentry={dataentry}
            rate={rate}
            payroll={payroll}
            logs={logs}
            myprofile={myprofile}
        />
    );
}
