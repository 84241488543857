import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Internal Components. */
import { CustomExportToolbar } from '../../CustomExport';

export default function ManifestUnassigned(props) {
    /** Get props from Manifest.js.  */
    const { unassigned } = props;

    /** Data for all history Datagird row. */
    const [unassignedMovement, setUnassignedMovement] = useState([]);

    /** Date format for datagrid. */
    const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };

    /** Initiallize datagrid row for unassigned movement data. */
    useEffect(() => {
        setUnassignedMovement(unassigned);
    }, [unassigned]);

    /** Define all unassigned movements Datagrid Fields. */
    const unassignedMovement_col = [
        { field: 'id', headerName: 'ID', flex: 0.5, align: 'center', headerAlign: 'center' },
        {
            field: 'move_date',
            headerName: 'Move Date',
            type: 'date',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        {
            field: 'cntr',
            headerName: 'Container#',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'size',
            headerName: 'Size',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pickup',
            headerName: 'Pick Up',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'delivery',
            headerName: 'Delivery',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'driver',
            headerName: 'Driver',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'method',
            headerName: 'Method',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.7,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'chassis',
            headerName: 'Chassis',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
        },
    ];

    return (
        <>
            <Box
                sx={{
                    height: 660,
                    '& .editable-cell': {
                        color: '#e36414',
                    },
                }}
            >
                <DataGridPremium
                    rows={unassignedMovement}
                    columns={unassignedMovement_col}
                    density="compact"
                    unstable_cellSelection
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: CustomExportToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                    sx={{ border: 'none' }}
                />
            </Box>
        </>
    );
}
