import * as React from 'react';
import { useRef, useLayoutEffect } from 'react';

/** Material UI Packages. */
import { TextField, MenuItem, Select } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';

/** Custom component for editing date-time cells in the DataGrid. */
export function CustomEditComponent(props) {
    const { id, value, field, hasFocus } = props;
    /** Access the DataGrid API context. */
    const apiRef = useGridApiContext();
    const ref = useRef();

    /** Focus the input field when the cell is focused. */
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    /** Handle changes to the date-time picker value. */
    const handleValueChange = (event) => {
        const newValue = event;
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                ref={ref}
                minDate={dayjs().subtract(3, 'day')}
                slotProps={{
                    textField: {
                        size: '0',
                        required: true,
                        variant: 'standard',
                        InputProps: {
                            disableUnderline: true,
                            style: { fontSize: '14px' },
                        },
                        InputLabelProps: {
                            style: { fontSize: '14px' },
                        },
                    },
                }}
                value={dayjs(value)}
                onChange={handleValueChange}
            />
        </LocalizationProvider>
    );
}

/** Custom component for editing movement status cells in the DataGrid. */
export const CustomStatusEditComponent = ({ id, value, api, field }) => {
    /** Get the entire row data. */
    const row = api.getRow(id);
    /** Extract the driver ID from the row data. */
    const driverId = row.driver_id;

    /** Handle changes to the status select field. */
    const handleChange = (event) => {
        api.setEditCellValue({ id, field, value: event.target.value });
    };

    /** Define the available status options with a conditional disabling for 'Completed' status. */
    const statusOptions = [
        { value: 'In Progress', label: 'In Progress' },
        { value: 'Completed', label: 'Completed', disabled: driverId === null },
        { value: 'Failed', label: 'Failed' },
        { value: 'Canceled', label: 'Canceled' },
        { value: 'Street Turned', label: 'Street Turned' },
    ];

    return (
        <TextField select value={value || ''} onChange={handleChange} fullWidth>
            {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value} disabled={option.disabled} sx={{ fontSize: '14px' }}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const CustomSelectComponent = ({ value, options, onChange }) => {
    return (
        <Select
            value={value}
            onChange={onChange}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 250,
                    },
                },
            }}
            sx={{ fontSize: '14px', width: '100%' }}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value} sx={{ fontSize: '14px' }}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};
