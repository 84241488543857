import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Typography, Stack, Paper } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

export default function HistorySubTable({ row: rowProp }) {
    /** State for payroll detail datagrid row. */
    const [movementRow, setMovementRow] = useState([]);

    /** Date format for datagrid. */
    const datetime_format = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };

    /** Initialize the payroll detail DataGrid rows based on the driver. */
    useEffect(() => {
        setMovementRow(rowProp.movement);
    }, [rowProp]);

    /** Columns configuration for the movement detail DataGrid. */
    const movement_col = [
        { field: 'id', headerName: 'ID', align: 'center', headerAlign: 'center', width: 50 },
        {
            field: 'deliveryDate',
            headerName: 'Move Date',
            align: 'center',
            headerAlign: 'center',
            width: 150,
            valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', datetime_format),
        },
        { field: 'pickup', headerName: 'Pick Up', align: 'center', headerAlign: 'center', width: 200 },
        {
            field: 'delivery',
            headerName: 'Delivery',
            align: 'center',
            headerAlign: 'center',
            width: 200,
        },
        {
            field: 'driver',
            headerName: 'Driver',
            align: 'center',
            headerAlign: 'center',
            width: 200,
        },
        { field: 'type', headerName: 'Type', align: 'center', headerAlign: 'center', width: 150 },
        { field: 'method', headerName: 'Method', align: 'center', headerAlign: 'center', width: 150 },
        { field: 'chassis_name', headerName: 'Chassis', align: 'center', headerAlign: 'center', width: 150 },
        { field: 'movementstatus', headerName: 'Status', align: 'center', headerAlign: 'center', width: 150 },
    ];

    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '99%', p: 1, pb: 2 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Typography variant="h6" color="textSecondary">
                        Movement Detail
                    </Typography>
                    <DataGridPremium
                        rows={movementRow || []}
                        columns={movement_col}
                        density="compact"
                        hideFooter
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        sx={{ fontSize: '0.8rem', width: '1450px' }}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}
