import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, IconButton, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { GridActionsCellItem, GridEditInputCell } from '@mui/x-data-grid-pro';

/** Material UI Icons. */
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddCircleIcon from '@mui/icons-material/AddCircle';

/** Internal Components. */
import NewMoveDialog from './NewMoveDialog';
import CustomSnackbar from '../../../CustomSnackbar';
import useApiRequest from '../../../useApiRequest';
import { CustomSkeleton } from '../../../CustomSkeleton';
import { CustomExportToolbar } from '../../../CustomExport';

import _ from 'lodash';

export default function NewContainer(props) {
    /**  Move DataGrid row state from Shedule.js */
    const {
        assigned,
        dispatch,
        userId,
        setDispatch,
        locationOptions,
        chassis,
        selectedId,
        setSelectedId,
        seletedCntr,
        setSelectedCntr,
        tempMoveRow,
        setTempMoveRow,
        newMovementDialogOpen,
        setNewMovementDialogOpen,
        newMove,
        setNewMove,
    } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /* Container Info Datagrid row. */
    const [newContainerRow, setNewContainerRow] = useState([]);
    /** State for adjusting box size. */
    const [checkSize, setCheckSize] = useState(400);
    /** State to manage loading when the refresh button is pressed. */
    const [subLoaded, setSubLoaded] = useState(true);

    /** Date format for datagrid. */
    const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const datetime_format = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };

    /** Update newContainerRow based on chassis type & info. */
    useEffect(() => {
        const updatedDispatch = [...dispatch].map((item) => {
            item.movement.forEach((val) => {
                if (val.chassis_name !== 'AIM CHASSIS' && val.chassis_name != null) {
                    item.aim_chassis = val.chassis_name;
                } else if (item.aim_chassis != null) {
                    item.aim_chassis = item.aim_chassis;
                }
            });
            return item;
        });
        setNewContainerRow(updatedDispatch);
    }, [dispatch]);

    /**
     * @brief Handles the click event to open the new movement dialog.
     * @param {Event} event -  click event.
     * @param {string} id -  ID of the movement.
     */
    const handleClickOpen = (event, id) => {
        event.preventDefault();
        setNewMovementDialogOpen(true);
        /** Deep clone dispatch information to filter the data while not affecting the original state. */
        const filteredMovement = _.cloneDeep(dispatch)
            .filter((item) => item.id === id)
            .map((item) => item.movement)
            .flat();
        const filteredContainer = _.cloneDeep(dispatch).find((item) => item.id === id).cntr;
        setTempMoveRow(filteredMovement);
        setSelectedCntr(filteredContainer);
        setSelectedId(id);
    };

    /** Function to handle Snackbar notifications. */
    const handleSnackbar = (message, severity) => {
        setSnackbarOpen(true);
        setSnackMsg(message);
        setSnackSeverity(severity);
    };

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        /** POST request to DB */
        apiRequest('schedule', 'dispatch', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    handleSnackbar(response.body.message, 'success');
                    setDispatch(response.body.data);
                } else {
                    handleSnackbar(response.body.message, 'error');
                }
            })
            .catch(() => {
                handleSnackbar('Something went wrong. Please try again.', 'error');
            });
        return updatedRow;
    };

    /** Refresh schedule data on button click. */
    const handleRefresh = () => {
        setSubLoaded(false);
        apiRequest('schedule', 'refresh', userId, [])
            .then((response) => {
                if (response.statusCode === 200) {
                    handleSnackbar(response.body.message, 'success');
                    setSubLoaded(true);
                    setDispatch(response.body.data);
                } else {
                    handleSnackbar(response.body.message, 'error');
                }
            })
            .catch(() => {
                handleSnackbar('Something went wrong. Please try again.', 'error');
            });
    };

    /** Handle change the box size for dispatch. */
    const handleSize = () => {
        setCheckSize((prevSize) => (prevSize === 400 ? 700 : 400));
    };

    /**
     * Change CSS according to dispatch status.
     * intensity : DEM.LFD / P.DIEM.LFD
     * attention : movements
     **/
    const getPriorityData = (params) => {
        const attention = params.value;
        const intensity = params.row.intensity;
        if (attention == 'Need Load Pick Up Move' || attention == 'Need Load Delivery Move') {
            if (intensity == true) {
                return 'urgent-road-cell';
            } else return 'normal-road-cell';
        } else if (attention == 'Need Empty Return Move' || attention == 'Need Empty Pick Up Move') {
            if (intensity == true) {
                return 'urgent-empty-cell';
            } else return 'normal-empty-cell';
        }
    };

    /** Change CSS according to the dispatch type(Import / Export). */
    const getDispatchTypeData = (params) => (params.row.dispatch_type ? 'import-cell' : 'export-cell');

    /** Change Empty Date Columns Css based on attention info. */
    const getEmptyDate = (params) => (params.row.attention.includes('Be Emptied') ? 'empty-date-cell' : '');

    /** Render AIM Chassis cells in DataGrid. */
    const renderAIMChassis = (params) => {
        const isEditable = !params.row.movement.some((item) => item.chassis_name === 'AIM CHASSIS');
        return <GridEditInputCell {...params} disabled={isEditable} />;
    };

    /** Define NewContainer Datagrid Fields. */
    const containerCol = [
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Move',
            width: 60,
            cellClassName: 'actions',
            align: 'center',
            headerAlign: 'center',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<LocalShippingIcon />}
                        label="Movement"
                        onClick={(event) => handleClickOpen(event, id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {
            field: 'dispatch_type',
            headerName: 'I/E',
            width: 30,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => (params.value === true ? 'I' : 'E'),
            cellClassName: getDispatchTypeData,
        },
        {
            field: 'team',
            headerName: 'Team',
            width: 80,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'client',
            headerName: 'Client',
            width: 150,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'mbl',
            headerName: 'Booking# / MBL#',
            width: 180,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'cntr',
            headerName: 'CNTR#',
            width: 140,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'size',
            headerName: 'Size',
            width: 70,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'terminal',
            headerName: 'Terminal',
            width: 160,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'dem',
            type: 'date',
            headerName: 'DEM.LFD',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: ({ value }) => (value ? new Date(value).toLocaleDateString('en-US', date_format) : null),
        },
        {
            field: 'pdiem',
            headerName: 'P.DIEM.LFD',
            type: 'date',
            width: 100,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'editable-cell',
            valueFormatter: ({ value }) => (value ? new Date(value).toLocaleDateString('en-US', date_format) : null),
        },
        {
            field: 'appt',
            headerName: 'APPT',
            width: 150,
            type: 'date',
            align: 'center',
            headerAlign: 'center',
            valueFormatter: ({ value }) =>
                value ? new Date(value).toLocaleDateString('en-US', datetime_format) : null,
        },
        {
            field: 'empty_date',
            headerName: 'Empty Date',
            type: 'date',
            width: 100,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'editable-cell',
            valueFormatter: ({ value }) => (value ? new Date(value).toLocaleDateString('en-US', date_format) : null),
            cellClassName: getEmptyDate,
        },
        {
            field: 'aim_chassis',
            headerName: 'AIM Chassis',
            width: 120,
            editable: true,
            headerClassName: 'editable-cell',
            align: 'center',
            headerAlign: 'center',
            renderEditCell: renderAIMChassis,
        },
        {
            field: 'attention',
            headerName: 'Attention',
            width: 230,
            align: 'center',
            headerAlign: 'center',
            cellClassName: getPriorityData,
        },
    ];

    return (
        <>
            <Box>
                <IconButton sx={{ p: 0, width: '70px', display: 'block', float: 'left' }} onClick={handleRefresh}>
                    <AutorenewIcon
                        fontSize="small"
                        sx={{ display: 'inline-block', verticalAlign: 'middle', color: '#747773' }}
                    />
                    <Typography
                        sx={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '0.8rem', color: '#a6a2a2' }}
                    >
                        Refresh
                    </Typography>
                </IconButton>
                <IconButton sx={{ p: 0, width: '30px', display: 'block', float: 'right' }} onClick={handleSize}>
                    <AddCircleIcon fontSize="small" sx={{ color: '#747773' }} />
                </IconButton>
            </Box>
            <Box
                sx={{
                    height: checkSize,
                    '& .editable-cell': {
                        color: '#e36414',
                    },
                    '& .urgent-road-cell': {
                        bgcolor: '#fcbf49',
                    },
                    '& .normal-road-cell': {
                        bgcolor: '#ffd7ba',
                    },
                    '& .urgent-empty-cell': {
                        bgcolor: '#5a6dff',
                    },
                    '& .normal-empty-cell': {
                        bgcolor: '#bbd0ff',
                    },
                    '& .import-cell': {
                        color: '#ff4d6d',
                        fontWeight: 'bold',
                    },
                    '& .export-cell': {
                        color: '#5390d9',
                        fontWeight: 'bold',
                    },
                    '& .empty-date-cell': {
                        bgcolor: '#e9ff70',
                    },
                }}
            >
                {subLoaded ? (
                    <DataGridPremium
                        rows={newContainerRow}
                        columns={containerCol}
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        hideFooter
                        density="compact"
                        processRowUpdate={processRowUpdate}
                        sx={{ border: 'none' }}
                        slots={{ toolbar: CustomExportToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />
                ) : (
                    <CustomSkeleton />
                )}
            </Box>
            <CustomSnackbar
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                snackMsg={snackMsg}
                snackSeverity={snackSeverity}
            />
            <NewMoveDialog
                dispatch={dispatch}
                setDispatch={setDispatch}
                selectedId={selectedId}
                locationOptions={locationOptions}
                userId={userId}
                assigned={assigned}
                tempMoveRow={tempMoveRow}
                setTempMoveRow={setTempMoveRow}
                newMovementDialogOpen={newMovementDialogOpen}
                setNewMovementDialogOpen={setNewMovementDialogOpen}
                setSnackbarOpen={setSnackbarOpen}
                setSnackMsg={setSnackMsg}
                setSnackSeverity={setSnackSeverity}
                containerNum={seletedCntr}
                chassis={chassis}
                newMove={newMove}
                setNewMove={setNewMove}
            />
        </>
    );
}
