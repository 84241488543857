import * as React from 'react';
import { useState, useRef, useLayoutEffect, useCallback, useEffect } from 'react';
import { Autocomplete, InputBase, Box } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

export default function AutoCompleteSelect(props) {
    const { params, arryOption } = props;
    const { id, value, field, hasFocus } = params;
    const [selectedValue, setSelectedValue] = useState(null);
    const [open, setOpen] = useState(false);
    const apiRef = useGridApiContext();

    const ref = useRef();

    useEffect(() => {
        const new_selectedValue = value ? [...arryOption].find((item) => item.id === value) : null;
        setSelectedValue(new_selectedValue);
    }, [value]);

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.querySelector('input').focus();
        }
    }, [hasFocus]);

    const handleChange = (params2, newValue) => {
        const new_value = newValue?.id;
        apiRef.current.setEditCellValue({ id, field, value: new_value });
    };

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'Enter' && !open) {
                const selectedOption = arryOption.find((option) => option.name === event.target.value);
                if (selectedOption) {
                    setSelectedValue(selectedOption);
                    const new_value = selectedOption.id;
                    apiRef.current.setEditCellValue({ id, field, value: new_value });
                    setOpen(false);
                }
            } else if (event.key === 'Tab' && open) {
                const selectedOption = arryOption.find((option) => option.name === event.target.value);
                if (selectedOption) {
                    setSelectedValue(selectedOption);
                    const new_value = selectedOption.id;
                    apiRef.current.setEditCellValue({ id, field, value: new_value });
                    setOpen(false);
                }
            }
        },
        [apiRef, field, id, open]
    );

    return (
        <Autocomplete
            ref={ref}
            options={arryOption}
            autoSelect // Allow user to select an option by highlighting the selection.
            includeInputInList
            forcePopupIcon={false} // Remove arrow and privillege to select an option, enabling quicker autocomplete.
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            value={selectedValue}
            onChange={(ev, e) => handleChange(params, e, ev)}
            onKeyDown={handleKeyDown}
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            fullWidth
            renderOption={(optionProps, option) => (
                <Box component="li" {...optionProps} sx={{ fontSize: '12px' }}>
                    {option.name}
                </Box>
            )}
            renderInput={(inputParams) => (
                <InputBase
                    fullWidth
                    id={inputParams.id}
                    inputProps={{
                        ...inputParams.inputProps,
                        autoComplete: 'new-password',
                    }}
                    {...inputParams.InputProps}
                    sx={{ fontSize: '12px' }}
                />
            )}
        />
    );
}
