import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Link, TextField, Paper } from '@mui/material';
import { DataGridPremium, GridRowEditStopReasons } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

/** Material UI Icons. */
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Library. */
import axios from 'axios';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';
import CustomToolbar from './CustomToolbar';
import AutoCompleteSelect from './AutoCompleteSelect';
import { customized_style } from '../../../theme/Styles';
import { CustomSkeletonVer2 } from '../../CustomSkeleton';

import dayjs from 'dayjs';

export default function DeliveryOrder(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /**  Redirect user if the token is invalid */
    const { getAccessTokenSilently } = useAuth0();

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** Initializing states for each API. */
    const [dispatch, setDispatch] = useState([]);
    const [client, setClient] = useState([]);
    const [terminal, setTerminal] = useState([]);
    const [yard, setYard] = useState([]);
    const [team, setTeam] = useState([]);
    const [size, setSize] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [driver, setDriver] = useState([]);

    /** State to display loading component until API response is received. */
    const [loaded, setLoaded] = useState(false);

    /* Delivery Order Datagrid row. */
    const [doRow, setDoRow] = useState([]);
    const [saveDisableBtn, setSaveDisableBtn] = useState(true);
    /** When double-click, the row will be activated on Datagrid. */
    const [rowModesModel, setRowModesModel] = useState({});
    const [copiedData, setCopiedData] = useState('');

    /** Date format for datagrid. */
    const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const datetime_format = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };

    /** Set document title. */
    useEffect(() => {
        document.title = 'Delivery Order - Insight Logistics | Dispatch ';
    }, []);

    /** Async API call to obtain dispatch - movement data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'deliveryorder',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setDispatch(resp.body.dispatch);
                setClient(resp.body.client);
                setTerminal(resp.body.terminal);
                setYard(resp.body.yard);
                setTeam(resp.body.team);
                setSize(resp.body.size);
                setDriver(resp.body.driver);
                const combined = [...resp.body.client, ...resp.body.terminal, ...resp.body.yard];
                setLocationOptions(combined);
                setLoaded(true);
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    /** Update newContainerRow based on dispatch. */
    useEffect(() => {
        setDoRow(dispatch);
    }, [dispatch]);

    /** Deactivate edit row mode on Datagrid. */
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    /** Datagrid Row delete handler for container Info.  */
    const handleDeleteClick = (id) => () => {
        if (window.confirm('Are you sure you want to delete the entry?')) {
            const deleted_obj = doRow.find((row) => row.id === id);
            /** POST request to DB */
            apiRequest('deliveryorder', 'dispatch-delete', userId, deleted_obj)
                .then((response) => {
                    if (response.statusCode === 200) {
                        setSnackbarOpen(true);
                        setSnackSeverity('success');
                        setSnackMsg(response.body.message);
                    } else {
                        setSnackbarOpen(true);
                        setSnackSeverity('error');
                        setSnackMsg(response.body.message);
                    }
                })
                .catch((error) => {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg('Something went wrong. Please try again.');
                });
            setDoRow(doRow.filter((row) => row.id !== id));
        }
    };

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = {
            ...newRow,
            mbl: newRow.mbl.replace(/\s+/g, '').toUpperCase(),
            cntr: newRow.cntr.replace(/\s+/g, '').toUpperCase(),
            vessel: newRow.vessel.toUpperCase(),
            isNew: true,
        };
        setDoRow(doRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setSaveDisableBtn(false);
        return updatedRow;
    };

    /** When double click, activate Row to change. */
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    /**
     * Applied CSS class compared to the demurrage LFD & appt.
     * Orange: from 4 days onwards
     * Yellow: if within 3 days from today
     * Red: if DEM.LFD has passed
     **/
    const getColorForDate = (params) => {
        const checkDemValue = params.value;
        const demValue = new Date(params.value);
        const apptValue = params.row.appt;
        const today = new Date();
        const twoDaysAfterTomorrow = new Date();
        const threeDaysLater = new Date();

        twoDaysAfterTomorrow.setDate(today.getDate() + 2);
        threeDaysLater.setDate(today.getDate() + 3);

        if (!apptValue) {
            if (checkDemValue) {
                if (dayjs(demValue).format('MM/DD/YY') >= dayjs(threeDaysLater).format('MM/DD/YY')) {
                    return 'orange-date-cell';
                } else if (
                    dayjs(demValue).format('MM/DD/YY') >= dayjs(today).format('MM/DD/YY') &&
                    dayjs(demValue).format('MM/DD/YY') <= dayjs(twoDaysAfterTomorrow).format('MM/DD/YY')
                ) {
                    return 'yellow-date-cell';
                } else {
                    return 'red-date-cell';
                }
            }
        }
    };

    const ApptInputValue = (props) => {
        /** Appointment Date Custom Filter Function */
        const { item, applyValue } = props;
        const handleFilterChange = (event) => {
            applyValue({ ...item, value: event.target?.value });
        };

        return (
            <TextField
                placeholder="YYYY-MM-DD"
                label="Date"
                variant="standard"
                onChange={handleFilterChange}
                value={item.value}
            />
        );
    };

    const IdInputValue = (props) => {
        /** Fields that require Custom Filter Function */
        const { item, applyValue } = props;

        const handleFilterChange = (event) => {
            applyValue({ ...item, value: event.target?.value });
        };

        return (
            <TextField
                placeholder="Search Name"
                label="Search Name"
                variant="standard"
                onChange={handleFilterChange}
                value={item.value}
            />
        );
    };

    /** Define NewContainer Datagrid Fields. */
    const doCol = [
        {
            field: 'dispatch_type',
            headerName: 'I/E',
            editable: true,
            align: 'center',
            headerAlign: 'center',
            width: 40,
            type: 'singleSelect',
            required: true,
            valueOptions: ['I', 'E'],
            valueGetter: (params) => {
                if (params.row.dispatch_type == true || params.row.dispatch_type == 'I') {
                    return 'I';
                } else if (params.row.dispatch_type == false || params.row.dispatch_type == 'E') {
                    return 'E';
                }
            },
        },
        {
            field: 'team',
            headerName: 'Team',
            width: 70,
            editable: true,
            required: true,
            align: 'center',
            headerAlign: 'center',
            renderEditCell: (params) => {
                return <AutoCompleteSelect params={{ ...params }} arryOption={team} />;
            },
            valueFormatter: (params) => {
                const option = team[team.findIndex((item) => item.id === params.value)]?.name;
                return option;
            },
        },
        {
            field: 'client',
            headerName: 'Client',
            width: 150,
            editable: true,
            required: true,
            align: 'center',
            headerAlign: 'center',
            renderEditCell: (params) => {
                return <AutoCompleteSelect params={{ ...params }} arryOption={client} />;
            },
            valueFormatter: (params) => {
                const option = client[client.findIndex((item) => item.id === params.value)]?.name;
                return option;
            },
            filterOperators: [
                {
                    label: 'Equal To',
                    value: 'Equal To',
                    getApplyFilterFn: (filterItem, col) => {
                        const value = filterItem.value?.toLowerCase() || '';
                        if (value === '') {
                            return true;
                        }
                        return (params) => {
                            const name = [...client][
                                [...client].findIndex((item) => item.id === params.value)
                            ]?.name.toLowerCase();
                            return name.includes(value);
                        };
                    },
                    InputComponent: IdInputValue,
                    InputComponentProps: { type: 'string' },
                },
            ],
        },
        {
            field: 'mbl',
            headerName: 'Booking# / MBL#',
            width: 170,
            editable: true,
            required: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'cntr',
            headerName: 'CNTR#',
            width: 150,
            editable: true,
            required: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'size',
            headerName: 'Size',
            required: true,
            width: 80,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            renderEditCell: (params) => {
                return <AutoCompleteSelect params={{ ...params }} arryOption={size} />;
            },
            valueFormatter: (params) => {
                const option = size[size.findIndex((item) => item.id === params.value)]?.name;
                return option;
            },
            filterOperators: [
                {
                    label: 'Equal To',
                    value: 'Equal To',
                    getApplyFilterFn: (filterItem, col) => {
                        const value = filterItem.value?.toLowerCase() || '';
                        if (value === '') {
                            return true;
                        }
                        return (params) => {
                            const name = [...size][
                                [...size].findIndex((item) => item.id === params.value)
                            ]?.name.toLowerCase();
                            return name.includes(value);
                        };
                    },
                    InputComponent: IdInputValue,
                    InputComponentProps: { type: 'string' },
                },
            ],
        },
        {
            field: 'terminal',
            headerName: 'Terminal',
            required: true,
            width: 150,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            renderEditCell: (params) => {
                return <AutoCompleteSelect params={{ ...params }} arryOption={terminal} />;
            },
            valueFormatter: (params) => {
                const option = terminal[terminal.findIndex((item) => item.id === params.value)]?.name;
                return option;
            },
            filterOperators: [
                {
                    label: 'Equal To',
                    value: 'Equal To',
                    getApplyFilterFn: (filterItem, col) => {
                        const value = filterItem.value?.toLowerCase() || '';
                        if (value === '') {
                            return true;
                        }
                        return (params) => {
                            const name = [...terminal][
                                [...terminal].findIndex((item) => item.id === params.value)
                            ]?.name.toLowerCase();
                            return name.includes(value);
                        };
                    },
                    InputComponent: IdInputValue,
                    InputComponentProps: { type: 'string' },
                },
            ],
        },
        {
            field: 'vessel',
            headerName: 'Vessel',
            editable: true,
            requried: true,
            align: 'center',
            headerAlign: 'center',
            width: 120,
        },
        {
            field: 'eta',
            type: 'date',
            headerName: 'ETA',
            width: 95,
            required: true,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        {
            field: 'etb',
            type: 'date',
            headerName: 'ETB',
            width: 95,
            editable: true,
            required: true,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },

        {
            field: 'dem',
            type: 'date',
            headerName: 'DEM.LFD',
            width: 100,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
            cellClassName: (params) => getColorForDate(params),
        },

        {
            field: 'appt',
            headerName: 'APPT',
            width: 140,
            type: 'dateTime',
            editable: true,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', datetime_format) : null,
            filterOperators: [
                {
                    label: 'Equal To',
                    value: 'Equal To',
                    getApplyFilterFn: (filterItem, col) => {
                        const value = filterItem.value || '';
                        if (value.length < 10) {
                            return true;
                        }
                        return (params) => value === params.value?.slice(0, 10);
                    },
                    InputComponent: ApptInputValue,
                    InputComponentProps: { type: 'string' },
                },
            ],
        },
        {
            field: 'obl',
            headerName: 'OBL',
            width: 80,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            type: 'boolean',
            cellClassName: (params) => (params.value ? '' : 'active-cell'),
        },
        {
            field: 'customs',
            headerName: 'Customs',
            width: 80,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            type: 'boolean',
            cellClassName: (params) => (params.value ? '' : 'active-cell'),
        },
        {
            field: 'pierpass',
            headerName: 'Pierpass',
            width: 80,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            type: 'boolean',
            cellClassName: (params) => (params.value ? '' : 'active-cell'),
        },
        {
            field: 'remark',
            headerName: 'Remark',
            width: 165,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            cellClassName: 'remark-cell',
        },
        {
            field: 'link',
            headerName: 'D/O File',
            width: 100,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Link href={params.row.link ? `${params.row.link}` : '#'} target="_blank" rel="noopener">
                    {params.row.link ? 'View File' : ''}
                </Link>
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 80,
            cellClassName: 'actions',
            align: 'center',
            headerAlign: 'center',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <>
            {loaded ? (
                <Paper sx={customized_style.itemPaper_main}>
                    <Box
                        sx={{
                            '& .active-cell': {
                                bgcolor: '#e9ff70',
                            },
                            '& .remark-cell': {
                                color: '#ef233c',
                                fontWeight: 'bold',
                            },
                            '& .orange-date-cell': {
                                bgcolor: '#fcbf49',
                                fontWeight: 'bold',
                            },
                            '& .yellow-date-cell': {
                                bgcolor: '#e9ff70',
                                fontWeight: 'bold',
                            },
                            '& .red-date-cell': {
                                bgcolor: '#ef233c',
                                fontWeight: 'bold',
                            },
                        }}
                    >
                        <DataGridPremium
                            rows={doRow}
                            columns={doCol}
                            density="compact"
                            editMode="row"
                            unstable_cellSelection
                            disableRowSelectionOnClick
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            onRowEditStop={handleRowEditStop}
                            processRowUpdate={processRowUpdate}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            slotProps={{
                                toolbar: {
                                    doRow,
                                    setDoRow,
                                    setRowModesModel,
                                    saveDisableBtn,
                                    setSaveDisableBtn,
                                    apiRequest,
                                    userId,
                                    setSnackbarOpen,
                                    setSnackSeverity,
                                    setSnackMsg,
                                    dispatch,
                                    setDispatch,
                                    showQuickFilter: true,
                                },
                            }}
                            sx={{
                                border: 'none',
                                fontSize: '12px',
                                '& .MuiDataGrid-cell': {
                                    border: '1px solid #e9ecef',
                                },
                                height: 720,
                            }}
                            onClipboardCopy={(copiedString) => setCopiedData(copiedString)}
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                        <CustomSnackbar
                            snackbarOpen={snackbarOpen}
                            setSnackbarOpen={setSnackbarOpen}
                            snackMsg={snackMsg}
                            snackSeverity={snackSeverity}
                        />
                    </Box>
                </Paper>
            ) : (
                <Paper sx={{ ...customized_style.itemPaper_main, p: 2 }}>
                    <CustomSkeletonVer2 />
                </Paper>
            )}
        </>
    );
}
