import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { TextField, Button, Grid, Divider, Stack, Box } from '@mui/material';
import { DataGridPremium, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

/** Material UI Icons. */
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';
import { customized_style } from '../../../theme/Styles';
import { CustomExportToolbar } from '../../CustomExport';

export default function AdditionalChargeEntry(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from DataEntry.js.  */
    const { addCharges, setAddCharges } = props;

    /** Custom API context */
    const apiRequest = useApiRequest();

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** Additional Charge Info Datagrid row. */
    const [additionalChargeRow, setAdditionalChargeRow] = useState([]);
    /** When double-click, the row will be activated on Datagrid. */
    const [rowModesModel, setRowModesModel] = useState({});

    /** Non-editable value for addtional charge datagrid. */
    const default_additional = (params) =>
        params.row.name === 'Overweight' ||
        params.row.name === 'Bobtail Start - 1st Yard' ||
        params.row.name === 'Bobtail Start - 2nd Yard' ||
        params.row.name === 'Others';

    /** Populate Driver info through API. */
    useEffect(() => {
        setAdditionalChargeRow(addCharges);
    }, [addCharges]);

    /* New charge info is added on Datagrid using submit button. */
    const addNewCharge = (event) => {
        event.preventDefault(); // To prevent Datagrid re-rendering.
        const form_data = new FormData(event.target);
        const last_id = additionalChargeRow.length === 0 ? 0 : additionalChargeRow[additionalChargeRow.length - 1].id;
        const new_data = {
            id: parseInt(last_id) + 1,
            name: form_data.get('name'),
            status: true,
        };
        const new_division = [...additionalChargeRow, new_data];
        setAdditionalChargeRow(new_division);
        apiRequest('dataentry', 'addCharge', userId, new_data)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setAddCharges(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });
    };

    /** Deactivate edit row mode on Datagrid. */
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    /** Datagrid Row edit handler for additional charge Info. */
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    /** Datagrid Row save handler for additional charge Info. */
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        setSnackbarOpen(true);
    };

    /** rowModesModel state change to View mode. */
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = additionalChargeRow.find((row) => row.id === id);
        if (editedRow.isNew) {
            setAdditionalChargeRow(additionalChargeRow.filter((row) => row.id !== id));
        }
    };

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setAdditionalChargeRow(additionalChargeRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        apiRequest('dataentry', 'addCharge', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setAddCharges(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });
        return updatedRow;
    };

    /** When double click, activate Row to change. */
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    /** Define additional charge Datagrid Fields. */
    const additionalCharge_col = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center' },
        {
            field: 'name',
            headerName: 'Addtional Charge Type',
            width: 300,
            editable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            editable: true,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 130,
            cellClassName: 'actions',
            align: 'center',
            headerAlign: 'center',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: '#00647e',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            sx={{
                                color: '#00647e',
                            }}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Grid container>
            <Grid item xs={4}>
                <Box component="form" onSubmit={addNewCharge}>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        label="Additional Charge Type"
                        name="name"
                        autoComplete="addtionalCharge_type"
                    />
                    <Stack direction="row" justifyContent="center">
                        <Button
                            type="submit"
                            variant="contained"
                            startIcon={<PersonAddAltIcon />}
                            sx={{ ...customized_style.itemButtons_add, width: '42%', mt: 3 }}
                        >
                            Add New Charge
                        </Button>
                        <CustomSnackbar
                            snackbarOpen={snackbarOpen}
                            setSnackbarOpen={setSnackbarOpen}
                            snackMsg={snackMsg}
                            snackSeverity={snackSeverity}
                        />
                    </Stack>
                </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mx: 3 }} />
            <Grid item xs={7.5} sx={{ height: 620 }}>
                <DataGridPremium
                    rows={additionalChargeRow}
                    columns={additionalCharge_col}
                    editMode="row"
                    unstable_cellSelection
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    disableRowSelectionOnClick
                    slots={{ toolbar: CustomExportToolbar }}
                    sx={customized_style.itemDatagrid}
                    isCellEditable={(params) => !default_additional(params)}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    pagination={true}
                    pageSizeOptions={[25, 50, 100]}
                />
            </Grid>
        </Grid>
    );
}
