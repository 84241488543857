import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Typography, Link } from '@mui/material';

export default function NewMoveInfo(props) {
    /** Get props from NewMoveDialog.js.  */
    const { selectedId, dispatch } = props;
    /** Return location information data. */
    const [linkList, setLinkList] = useState([]);

    /** Initialize return location name with link.  */
    useEffect(() => {
        const terminal_list = [...dispatch].find((item) => item.id === selectedId)?.terminal_recommendation;
        setLinkList(terminal_list);
    }, [dispatch]);

    return (
        <Box component="form">
            <Typography sx={{ color: '#00647e', fontWeight: 'bold', display: 'inline-block' }}>
                Return Terminal Location
            </Typography>
            <Box sx={{ width: '100%', border: '1px solid #adb5bd', borderRadius: '5px', mt: 2, p: 1 }}>
                {linkList.map((item, idx) => (
                    <Typography key={idx} sx={{ color: '#6c757d', fontSize: '0.8rem', pb: 1 }}>
                        {'>> '}
                        <Link
                            href={item.partner_link}
                            underline="none"
                            target="_blank"
                            rel="noopener"
                            sx={{
                                color: '#6c757d',
                                ':hover': {
                                    textDecoration: 'underline',
                                    color: '#003a52',
                                },
                            }}
                        >
                            {item.partner_name}
                        </Link>
                    </Typography>
                ))}
            </Box>
        </Box>
    );
}
