import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';

ReactDOM.createRoot(document.getElementById('root')).render(
    <Auth0Provider
        domain="insightshippinginc.us.auth0.com"
        clientId="RpkkcDZPxuuI1pOE0GbYbC9Pc1vKADwV"
        authorizationParams={{
            redirect_uri: window.location.origin + '/dashboard',
            audience: 'https://auth0.insightshippinginc.io',
        }}
    >
        <App />
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
