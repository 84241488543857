import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Internal Components. */
import { dashboard_style } from '../../../theme/Styles';

export function DashboardDemTable(props) {
    /** Get props from Dashboard.js.  */
    const { demData } = props;

    /** Urgent Demurrage date for Datagrid Row. */
    const [demtable, setDemtable] = useState([]);
    /** Automatically soring by perdiem date. */
    const [sortModel, setSortModel] = useState([{ field: 'dem_date', sort: 'asc' }]);

    /** Date format for datagrid. */
    const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };

    /** Input table data. */
    useEffect(() => {
        const local_demData = [...demData].map((item) => {
            item.dem_date = new Date(item.dem_date).toLocaleDateString();
            return item;
        });
        setDemtable(local_demData);
    }, [demData]);

    /** Define urgent Demurrage Datagrid Fields. */
    const dem_col = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            hideable: false,
            hide: true,
            pinnable: false,
        },
        {
            field: 'client',
            headerName: 'Client',
            width: 130,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            hideable: false,
            pinnable: false,
        },
        {
            field: 'cntr',
            headerName: 'CNTR#',
            width: 130,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            hideable: false,
            pinnable: false,
        },
        {
            field: 'dem_date',
            headerName: 'DEM Date',
            headerClassName: 'super-app-theme--header',
            cellClassName: 'super-app-cell--header',
            type: 'date',
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', date_format),
            hideable: false,
            pinnable: false,
        },
    ];

    return (
        <>
            <Box
                sx={{
                    height: 580,
                    width: '100%',
                    '& .super-app-theme--header': {
                        color: '#ff4d6d',
                    },
                    '& .super-app-cell--header': {
                        fontWeight: 'bold',
                    },
                }}
            >
                <DataGridPremium
                    rows={demtable}
                    columns={dem_col}
                    sortModel={sortModel}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    hideFooter
                    sx={dashboard_style.itemDatagrid}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                />
            </Box>
        </>
    );
}
export function DashboardPdiemTable(props) {
    /** Get props from Dashboard.js.  */
    const { perdiemData } = props;

    /** Urgent PerDiem date for Datagrid Row. */
    const [pdiemTable, setPdiemTable] = useState([]);
    /** Automatically soring by perdiem date. */
    const [sortModel, setSortModel] = useState([{ field: 'pdiem_date', sort: 'asc' }]);

    /** Date format for datagrid. */
    const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };

    /** Input table data. */
    useEffect(() => {
        const local_pdiemData = [...perdiemData].map((item) => {
            item.pdiem_date = new Date(item.pdiem_date).toLocaleDateString();
            return item;
        });
        setPdiemTable(local_pdiemData);
    }, [perdiemData]);

    /** Define urgent PerDiem Datagrid Fields. */
    const pdiem_col = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            hideable: false,
            hide: true,
            pinnable: false,
        },
        {
            field: 'client',
            headerName: 'Client',
            width: 130,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            hideable: false,
            pinnable: false,
        },
        {
            field: 'cntr',
            headerName: 'CNTR#',
            width: 130,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            hideable: false,
            pinnable: false,
        },
        {
            field: 'pdiem_date',
            headerName: 'P.Diem Date',
            headerClassName: 'super-app-theme--header',
            cellClassName: 'super-app-cell--header',
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', date_format),
            hideable: false,
            pinnable: false,
        },
    ];

    return (
        <>
            <Box
                sx={{
                    height: 580,
                    width: '100%',
                    '& .super-app-theme--header': {
                        color: '#ff4d6d',
                    },
                    '& .super-app-cell--header': {
                        fontWeight: 'bold',
                    },
                }}
            >
                <DataGridPremium
                    rows={pdiemTable}
                    columns={pdiem_col}
                    sortModel={sortModel}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    hideFooter
                    sx={dashboard_style.itemDatagrid}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                />
            </Box>
        </>
    );
}
