import * as React from 'react';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Library. */
import axios from 'axios';

/** Use Context to make API POST calls. */
const useApiRequest = () => {
    const { getAccessTokenSilently } = useAuth0();
    /** Parameters to payload. */
    const apiRequest = async (type, method, userId, obj) => {
        try {
            /** Authorize the request through token. */
            const token = await getAccessTokenSilently({
                authorizationParams: {
                    audience:
                        'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                },
            });
            const response = await axios.post('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', obj, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    type: type,
                    method: method,
                    userId: userId,
                },
            });
            const resp = response.data['body-json'];
            /** Return response calls. */
            return resp;
        } catch (error) {
            throw error;
        }
    };
    return apiRequest;
};

export default useApiRequest;
