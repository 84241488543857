/**
 * @brief Function handling the end of a drag-and-drop operation.
 *
 *
 * @param {*} result - The result object containing information about the drag-and-drop operation.
 * @param {*} taskStatusCol
 * @param {*} setTaskStatusCol
 * @param {*} setSaveBtnDisable
 * @param {*} dispatch
 * @param {*} setDispatch
 * @returns taskStatusCol - The updated taskStatusCol after the drag-and-drop operation.
 */
export const onDragEnd = (result, taskStatusCol, setTaskStatusCol, setSaveBtnDisable, dispatch, setDispatch) => {
    // Do nothing if dropped outside a droppable area
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
        // If a card is moved to a different column
        const sourceColumn = taskStatusCol[source.droppableId]; // Retrieve the source column of the dragged card (Starting location).
        const destColumn = taskStatusCol[destination.droppableId]; // Retrieve the destination column of the dragged card (Destination location).
        const sourceItems = [...sourceColumn.items]; // Create a copy of the remaining card objects in the sourceColumn.
        const destItems = [...destColumn.items]; // Create a copy of the moved card objects in the destination column.
        const [removed] = sourceItems.splice(source.index, 1); // Remove the dragged card from the sourceColumn.
        destItems.splice(destination.index, 0, removed); // Insert the dragged card into the destination column.
        // order_by property based on index.
        destItems.map((item, idx) => {
            item.order_by = idx;
            return item;
        });
        // Conditional mapping to update 'driver_id' property in destItems based on destColumn's 'driver_id'.
        {
            destColumn.driver_id == null
                ? destItems.map((item) => {
                      item.driver_id = null;

                      return item;
                  })
                : destItems.map((item) => {
                      item.driver_id = destColumn.driver_id;

                      return item;
                  });
        }

        // Update the state with the modified columns
        setTaskStatusCol({
            ...taskStatusCol,
            [source.droppableId]: {
                ...sourceColumn,
                items: sourceItems,
            },
            [destination.droppableId]: {
                ...destColumn,
                items: destItems,
            },
        });
    } else {
        // If a card is moved within the same column or only the order is changed.
        const column = taskStatusCol[source.droppableId]; // Retrieve the column from which the card is moved (Starting loction).
        const copiedItems = [...column.items]; // Create a copy of all card values in the column.
        const [removed] = copiedItems.splice(source.index, 1); // Remove the dragged card from its original position..
        copiedItems.splice(destination.index, 0, removed); // Insert the dragged card into the new position.
        // Order_by property based on index.
        copiedItems.map((item, idx) => {
            item.order_by = idx;
            return item;
        });
        // Update the state with the modified column.
        setTaskStatusCol({
            ...taskStatusCol,
            [source.droppableId]: {
                ...column,
                items: copiedItems,
            },
        });
    }

    // Update driver_id and order_by of the movement into dispatch.
    const filtered_dispatch = [...dispatch].map((item) => {
        item.movement.map((el) => {
            Object.entries(taskStatusCol).map((val) => {
                val[1].items.map((obj) => {
                    if (el.id == obj.id) {
                        el.driver_id = obj.driver_id;
                        el.order_by = obj.order_by;
                    }
                    return obj;
                });
                return val;
            });
            return el;
        });
        return item;
    });
    setSaveBtnDisable(false);
    setDispatch(filtered_dispatch);
};
