import * as React from 'react';
import { useState } from 'react';

/** Material UI Packages. */
import { Fab } from '@mui/material';

/** Material UI Icons. */
import EditNoteIcon from '@mui/icons-material/EditNote';

/** Internal Components. */
import MemoDialog from './MemoDialog';
import CustomSnackbar from '../../../CustomSnackbar';
import useApiRequest from '../../../useApiRequest';

export default function MemoFab(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** State for datagrid row. */
    const [memoRow, setMemoRow] = useState([]);
    /** Dialog open state using Fab component. */
    const [memoOpen, setMemoOpen] = useState(false);

    /** Handler to open dialog & Receive memo data through API. */
    const handleFabClick = () => {
        setMemoOpen(true);
        apiRequest('schedule', 'memo-read', userId, [])
            .then((response) => {
                if (response.statusCode === 200) {
                    setMemoRow(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });
    };

    return (
        <>
            <Fab
                size="medium"
                color="primary"
                aria-label="add"
                sx={{ position: 'absolute', bottom: 135, right: 16 }}
                onClick={handleFabClick}
            >
                <EditNoteIcon />
            </Fab>
            <MemoDialog
                userId={userId}
                memoOpen={memoOpen}
                memoRow={memoRow}
                setMemoRow={setMemoRow}
                setMemoOpen={setMemoOpen}
                setSnackbarOpen={setSnackbarOpen}
                setSnackMsg={setSnackMsg}
                setSnackSeverity={setSnackSeverity}
            />
            <CustomSnackbar
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                snackMsg={snackMsg}
                snackSeverity={snackSeverity}
            />
        </>
    );
}
