import * as React from 'react';
import { useState, useRef, useLayoutEffect, useCallback, useEffect } from 'react';

/** Material UI Packages. */
import { Autocomplete, InputBase, Box } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

export default function AutoCompleteSelect(props) {
    /** Get props from App.js.  */
    const { params, arryOption } = props;

    const { id, value, field, hasFocus } = params;

    /** State for currently selected value. */
    const [selectedValue, setSelectedValue] = useState(null);
    /** State for managing autocomplete drop down. */
    const [open, setOpen] = useState(false);

    /** Set a ref to access the input element. */
    const apiRef = useGridApiContext();
    /** Used to directly access a specific DOM element. */
    const ref = useRef();

    /** Updates selectedValue whenever the value changes. */
    useEffect(() => {
        const new_selectedValue = value ? [...arryOption].find((item) => item.id === value) : null;
        setSelectedValue(new_selectedValue); // 옵션객체
    }, [value]);

    /**
     * Runs synchronously after DOM updates.
     * Sets focus on the input element when hasFocus is true.
     */
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.querySelector('input').focus();
        }
    }, [hasFocus]);

    /** Handle to set a new value and update the cell value in the grid. */
    const handleChange = (params2, newValue) => {
        const new_value = newValue?.id;
        apiRef.current.setEditCellValue({ id, field, value: new_value });
    };

    const handleKeyDown = useCallback(
        (event) => {
            // Stop edit mode when pressing 'Enter' key and the dropdown is not open.
            if (event.key === 'Enter' && !open) {
                apiRef.current.stopRowEditMode({ id, field });
            }
            // Handles the selected option when pressing 'Tab' key and the dropdown is open.
            else if (event.key === 'Tab' && open) {
                const selectedOption = arryOption.find((option) => option.name === event.target.value);
                if (selectedOption) {
                    setSelectedValue(selectedOption);
                    const new_value = selectedOption.id;
                    apiRef.current.setEditCellValue({ id, field, value: new_value });
                    setOpen(false);
                }
            }
        },
        [apiRef, field, id, open]
    );

    return (
        <Autocomplete
            ref={ref} // Provides a reference to the <Autocomplete /> component, allowing access to its DOM node for operations like focusing.
            options={arryOption} //Array of options available for the autocomplete dropdown.
            autoSelect // Allow user to select an option by highlighting the selection.
            includeInputInList // Ensures the input value is included in the list of options.
            forcePopupIcon={false} // Removes the popup icon to provide a quicker autocomplete experience.
            getOptionLabel={(option) => option?.name || ''} // Defines the label to display for each option.
            isOptionEqualToValue={(option, value) => option?.value === value?.value} //Function to determine if an option is equal to the current value.
            value={selectedValue} // Specifies the currently selected value.
            onChange={(ev, e) => handleChange(params, e, ev)} // Function called when the value changes.
            onKeyDown={handleKeyDown} // Function called when a key is pressed.
            open={open} //Controls whether the autocomplete dropdown is open.
            // Function called when the autocomplete dropdown closes.
            onClose={() => {
                setOpen(false);
            }}
            // Function called when the autocomplete dropdown opens.
            onOpen={() => {
                setOpen(true);
            }}
            fullWidth
            // Defines how each option is rendered.
            renderOption={(optionProps, option) => (
                <Box component="li" {...optionProps} sx={{ fontSize: '12px' }}>
                    {option.name}
                </Box>
            )}
            // Defines how the input field is rendered.
            renderInput={(inputParams) => (
                <InputBase
                    fullWidth
                    id={inputParams.id}
                    inputProps={{ ...inputParams.inputProps }}
                    {...inputParams.InputProps}
                    sx={{ fontSize: '12px' }}
                />
            )}
        />
    );
}
