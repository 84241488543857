import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Grid, List, ListItem, ListItemIcon, ListItemText, Checkbox, Button, Paper, Stack } from '@mui/material';

/** Material UI Icons. */
import SaveIcon from '@mui/icons-material/Save';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';
import { customized_style } from '../../../theme/Styles';

import _ from 'lodash';

/** Handler to filter only unchecked listItem. */
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

/** Handler to filter checked listItem. */
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function CityEntry(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from DataEntry.js.  */
    const { city, setCity } = props;

    /** Custom API context */
    const apiRequest = useApiRequest();

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** Transfer List states. */
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    /** Populate city info through API. */
    useEffect(() => {
        const active_city = _.cloneDeep(city)
            .filter((item) => item.status === true)
            .map((item) => item.name);
        const inactive_city = _.cloneDeep(city)
            .filter((item) => item.status !== true)
            .map((item) => item.name);
        setRight(active_city);
        setLeft(inactive_city);
    }, [city]);

    /** Add city list */
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    /** All left listItems move to right list. */
    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    /** Some left listItems move to right list. */
    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    /** Some right listItems move to left list. */
    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    /** All right listItems move to left list. */
    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    /** Showing all registered city in transfer list(both). */
    const createCityList = (items) => (
        <Paper sx={{ width: 250, height: 500, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.length > 0 ? (
                    items.map((value) => {
                        const labelId = `transfer-list-item-${value}-label`;
                        return (
                            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value} />
                            </ListItem>
                        );
                    })
                ) : (
                    <></>
                )}
            </List>
        </Paper>
    );

    /** Handler to save the updated citylist & open Snackbar. */
    const handleSaveBtn = () => {
        apiRequest('dataentry', 'city', userId, right)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setCity(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });
    };

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: 553 }}>
                <Grid item>{createCityList(left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={left?.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={right?.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{createCityList(right)}</Grid>
            </Grid>
            <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                <Button
                    startIcon={<SaveIcon />}
                    sx={{ ...customized_style.itemButtons_add, width: '10%' }}
                    onClick={handleSaveBtn}
                >
                    SAVE
                </Button>
                <CustomSnackbar
                    snackbarOpen={snackbarOpen}
                    setSnackbarOpen={setSnackbarOpen}
                    snackMsg={snackMsg}
                    snackSeverity={snackSeverity}
                />
            </Stack>
        </>
    );
}
