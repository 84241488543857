import * as React from 'react';

/** Material UI Packages. */
import { Fab, Avatar } from '@mui/material';

export default function ScheduleFab({ type, bottomPosition, openUrl }) {
    /** Handler to open dialog. */
    const handleFabClick = () => {
        return window.open(openUrl, '_blank');
    };

    return (
        <Fab
            size="medium"
            aria-label="add"
            sx={{ position: 'absolute', bottom: bottomPosition, right: 16, bgcolor: '#e8f1f2' }}
            onClick={handleFabClick}
        >
            <Avatar src={type} />
        </Fab>
    );
}
