import * as React from 'react';
import { useState } from 'react';

/** Material UI Packages. */
import { Snackbar, Alert } from '@mui/material';

export default function CustomSnackbar(props) {
    /** Receiving props from each componenets. */
    const { snackbarOpen, setSnackbarOpen, snackMsg, snackSeverity } = props;

    /** Handler to close the snackbar */
    const handleAlertClose = (event, reason) => {
        // Do nothing if the click is outside the snackbar
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleAlertClose}>
            <Alert onClose={handleAlertClose} severity={snackSeverity} sx={{ width: '100%' }}>
                {snackMsg}
            </Alert>
        </Snackbar>
    );
}
