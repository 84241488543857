import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Tabs, Tab, Typography, useTheme, Paper } from '@mui/material';
import PropTypes from 'prop-types';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Liabrary. */
import axios from 'axios';

/** Internal Components. */
import TradePartnerEntry from './TradePartnerEntry';
import DriverEntry from './DriverEntry';
import AdditionalChargeEntry from './AdditionalChargeEntry';
import CityEntry from './CityEntry';
import DivisionEntry from './DivisionEntry';
import ChassisEntry from './ChassisEntry';
import { CustomSkeletonVer2 } from '../../CustomSkeleton';
import { customized_style } from '../../../theme/Styles';

/** Create tabPanel component. */
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/** To run typechecking on the props for a component. */
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Dataentry(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /**  Redirect user if the token is invalid */
    const { getAccessTokenSilently } = useAuth0();

    const dataEntryTheme = useTheme();

    /** Tab index value. */
    const [tabValue, setTabValue] = useState(0);

    /** API result in state */
    const [trader, setTrader] = useState([]);
    const [city, setCity] = useState([]);
    const [team, setTeam] = useState([]);
    const [driver, setDriver] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [addCharges, setAddCharges] = useState([]);
    const [chassis, setChassis] = useState([]);

    const [loaded, setLoaded] = useState(false);

    /** Set document title. */
    useEffect(() => {
        document.title = 'Data Entry - Insight Logistics | Dispatch ';
    }, []);

    /** Handler to change tabValue . */
    const tabHandleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    /** Async API call to obtain dispatch - movement data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'dataentry',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setTrader(resp.body.trader);
                setCity(resp.body.city);
                setTeam(resp.body.team);
                setDriver(resp.body.driver);
                setCarriers(resp.body.carrier);
                setAddCharges(resp.body.addCharge);
                setChassis(resp.body.chassis);
                setLoaded(true);
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    return (
        <>
            {loaded ? (
                <Paper sx={customized_style.itemPaper_main}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tabValue}
                            onChange={tabHandleChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                        >
                            <Tab label="TRADE PARTNER ENTRY" />
                            <Tab label="DRIVER ENTRY" />
                            <Tab label="ADDITIONAL CHARGE ENTRY" />
                            <Tab label="CITY ENTRY" />
                            <Tab label="DIVISION ENTRY" />
                            <Tab label="CHASSIS ENTRY" />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0} dir={dataEntryTheme.direction}>
                        <TradePartnerEntry
                            cityInfo={city}
                            trader={trader}
                            userId={userId}
                            carriers={carriers}
                            setTrader={setTrader}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} dir={dataEntryTheme.direction}>
                        <DriverEntry driver={driver} userId={userId} setDriver={setDriver} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2} dir={dataEntryTheme.direction}>
                        <AdditionalChargeEntry addCharges={addCharges} userId={userId} setAddCharges={setAddCharges} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={3} dir={dataEntryTheme.direction}>
                        <CityEntry city={city} userId={userId} setCity={setCity} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={4} dir={dataEntryTheme.direction}>
                        <DivisionEntry team={team} userId={userId} setTeam={setTeam} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={5} dir={dataEntryTheme.direction}>
                        <ChassisEntry userId={userId} chassis={chassis} setChassis={setChassis} />
                    </TabPanel>
                </Paper>
            ) : (
                <Paper sx={{ ...customized_style.itemPaper_main, p: 2 }}>
                    <CustomSkeletonVer2 />
                </Paper>
            )}
        </>
    );
}
