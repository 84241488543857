import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Divider, Typography, Button } from '@mui/material';

/** Internal Components. */
import useApiRequest from '../../useApiRequest';

/** HTTP Client Library. */
import axios from 'axios';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

export default function ManifestCount(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from ManifestAssigned.js.  */
    const {
        count,
        setCount,
        setInprogressRow,
        setCompleteRow,
        active,
        setActive,
        setLoadedInprogress,
        setLoadedComplete,
    } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** When status is true, completeQTY should be added up. */
    const [completeQTY, setCompleteQTY] = useState(null);
    /** Rest Movement QTY except for complete QTY. */
    const [inprogressQTY, setInprogressQTY] = useState(null);

    const [apiRequestChk, setApiRequestChk] = useState(null);

    const [storedSearchVal, setStoredSearchVal] = useState({});

    const { getAccessTokenSilently } = useAuth0();

    const getAssigned = async (props) => {
        const { searchValue, apiRequestChk, param } = props;
        let controller;

        if (!apiRequestChk) {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://insightshippinginc.us.auth0.com/api/v2/',
                    },
                });
                const resp = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: param,
                        date_from: searchValue?.date_from,
                        date_to: searchValue?.date_to,
                        search_input: searchValue?.search_input,
                        search_type: searchValue?.search_type,
                    },
                    signal: controller.signal,
                });
                controller.abort();
                return resp;
            } catch (e) {
                throw e;
            }
        }
    };

    useEffect(() => {
        getAssigned(storedSearchVal, apiRequestChk, 'inprogress')
            .then((response) => {
                const resp = response.data['body-json'];
                console.log(resp);
            })
            .catch((error) => {});
        return;
    }, [apiRequestChk]);

    /** Effect for updating quantities based on movement status. */
    useEffect(() => {
        setInprogressQTY(count.inprogress_len);
        setCompleteQTY(count.complete_len);
    }, [count]);

    /** Handler for fetching in progress movements */
    const handleInprogress = () => {
        setActive(true);
        setLoadedComplete(false);
        setApiRequestChk(true);
        /** POST request to DB */
        apiRequest('manifest', 'inprogress', userId, [])
            .then((response) => {
                if (response.statusCode === 200) {
                    setInprogressRow(response.body.allMovementRow);
                    setCount(response.body.count);
                    setLoadedInprogress(true);
                    setApiRequestChk(false);
                } else {
                }
            })
            .catch((error) => {
                console.error('Error fetching in-progress movements:', error);
            });
    };

    /** Handler for fetching completed movements */
    const handleComplete = () => {
        setActive(false);
        setApiRequestChk(true);
        const storedSearchValue = sessionStorage.getItem('searchValue')
            ? JSON.parse(sessionStorage.getItem('searchValue'))
            : [];
        setLoadedComplete(true);
        setLoadedInprogress(false);
        /** POST request to DB */
        apiRequest('manifest', 'search', userId, storedSearchValue)
            .then((response) => {
                if (response.statusCode === 200) {
                    setCompleteRow(response.body.allMovementRow);
                    setCount(response.body.count);
                    setApiRequestChk(false);
                } else {
                }
            })
            .catch((error) => {
                console.error('Error fetching completed movements:', error);
            });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                m: 0,
                color: 'text.secondary',
            }}
        >
            <Button onClick={handleInprogress} sx={{ p: 0 }}>
                <Typography
                    sx={{
                        color: active ? '#003a52' : '#00647e',
                        mx: 1,
                        fontSize: '1.2rem',
                        fontWeight: active ? 'bold' : 'regular',
                    }}
                >
                    IN PROGRESS : {inprogressQTY}
                </Typography>
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button onClick={handleComplete} sx={{ p: 0 }}>
                <Typography
                    sx={{
                        color: '#00647e',
                        mx: 1,
                        fontSize: '1.2rem',
                        color: active ? '#00647e' : '#003a52',
                        fontWeight: active ? 'regular' : 'bold',
                    }}
                >
                    COMPLETE : {completeQTY}
                </Typography>
            </Button>
        </Box>
    );
}
