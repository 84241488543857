import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

/** Material UI Packages. */
import { Typography, Stack, Paper, Grid } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Internal Components. */
import PayrollAddChargeSub from './PayrollAddChargeSub';
import { usdPrice } from '../../CustomFormat';

import dayjs from 'dayjs';

export default function PayrollDataSub({ row: rowProp, dateRange: dateRange, weeklyPayroll: weeklyPayroll }) {
    /** State for payroll detail datagrid row. */
    const [payrollDetailRow, setPayrollDetailRow] = useState([]);

    /** Initialize the payroll detail DataGrid rows based on the driver. */
    useEffect(() => {
        const filtered_driver = [...weeklyPayroll].filter((item) => {
            return item.driver_name == rowProp.driver_name;
        });
        setPayrollDetailRow(filtered_driver);
    }, [weeklyPayroll]);

    /** Columns configuration for the movement detail DataGrid. */
    const payrollDetail_col = [
        {
            field: 'move_date',
            headerName: 'Move Date',
            align: 'center',
            headerAlign: 'center',
            width: 90,
            valueFormatter: (params) => dayjs(params.value).format('MM/DD/YY'),
        },
        { field: 'container', headerName: 'CNTR', align: 'center', headerAlign: 'center', width: 120 },
        { field: 'pickup', headerName: 'Pick Up', align: 'center', headerAlign: 'center', width: 140 },
        {
            field: 'delivery_to',
            headerName: 'Delivery',
            align: 'center',
            headerAlign: 'center',
            width: 140,
        },
        { field: 'type', headerName: 'Type', align: 'center', headerAlign: 'center', width: 80 },
        { field: 'method', headerName: 'Method', align: 'center', headerAlign: 'center', width: 85 },
        {
            field: 'base_rate',
            headerName: 'Base Rate',
            align: 'center',
            headerAlign: 'center',
            width: 95,
            ...usdPrice,
        },
        {
            field: 'add_rate',
            headerName: "Add'l Rate",
            align: 'center',
            headerAlign: 'center',
            width: 95,
            ...usdPrice,
        },
        { field: 'total', headerName: 'Sub Total', align: 'center', headerAlign: 'center', width: 85, ...usdPrice },
    ];

    /**
     * Callback function to get the content of the detail panel for a row in the DataGrid.
     * If the row has additional rate details, it renders the PayrollAddChargeSub component.
     */
    const getDetailPanelContent = useCallback(
        ({ row }) => {
            if (row.add_rate_detail && row.add_rate_detail.length > 0) {
                return <PayrollAddChargeSub row={row} />;
            }
            return null;
        },
        [weeklyPayroll]
    );
    /** Returns 'auto' to allow the panel to adjust its height based on the content. */
    const getDetailPanelHeight = useCallback(() => 'auto', []);

    return (
        <Stack sx={{ py: 3, height: '100%', boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '92%', p: 1, pb: 2 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Typography variant="h6" color="textSecondary">
                        Payroll Detail
                    </Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="black">
                                Name: {rowProp.driver_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="black">
                                Period: {dateRange}
                            </Typography>
                        </Grid>
                    </Grid>
                    <DataGridPremium
                        rows={payrollDetailRow || []}
                        columns={payrollDetail_col}
                        density="compact"
                        hideFooter
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        getRowHeight={() => 'auto'}
                        sx={{ fontSize: '0.8rem' }}
                        getDetailPanelHeight={getDetailPanelHeight}
                        getDetailPanelContent={getDetailPanelContent}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}
