import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Dialog, Box, AppBar, Toolbar, IconButton, Typography, Divider } from '@mui/material';
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium';

/** Material UI Icons. */
import CloseIcon from '@mui/icons-material/Close';

/** Internal Components. */
import useApiRequest from '../../../useApiRequest';

import _ from 'lodash';

export default function ChassisDialog(props) {
    /** Get props from App.js. */
    const { userId } = props;
    /** Get props from Schedule.js. */
    const { dispatch, chassis, chassisLocation, setChassisLocation } = props;
    /** Get props from ChassisStatus.js. */
    const { chassisOpen, setChassisOpen, setSnackbarOpen, setSnackSeverity, setSnackMsg } = props;

    /** Custom API Request function. */
    const apiRequest = useApiRequest();

    /** State for datagrid row.  */
    const [chassisRow, setChassisRow] = useState([]);
    /** State for Chassis QTY managing. */
    const [chassisQTY, setChassisQTY] = useState(0);
    const [availQTY, setAvailQTY] = useState(0);

    /** When open the dialog, chassisRow will be populate based on filtered data.  */
    useEffect(() => {
        const chassis_name = chassis.map((val) => val.name);
        const movements = _.cloneDeep(dispatch)
            .map((item) => item.movement)
            .flat()
            .filter((val) => val.chassis_id != null && val.chassis_name != null && val.movementstatus != 'Canceled');

        const formatted_date = movements.map((val) => {
            val.move_date = new Date(val.move_date);
            return val;
        });
        const sorted_date = formatted_date.sort((a, b) => b.move_date - a.move_date);
        const find_last = chassis_name
            .map((item) => {
                return sorted_date.find((val) => val.chassis_name == item);
            })
            .filter((item) => item != undefined);
        const updated_table = [...chassisLocation].map((itemA) => {
            const matchedItemB = find_last.find((itemB) => itemB.chassis_name === itemA.name);
            if (matchedItemB) {
                if (itemA.container == matchedItemB.container) {
                    return { ...itemA, container: matchedItemB.container };
                } else return { ...itemA, location: null, container: matchedItemB.container };
            }
            return itemA;
        });
        setChassisRow(updated_table);
    }, [chassisOpen]);

    /** Update the quantity of chassis and available chassis based on chassisRow. */
    useEffect(() => {
        setChassisQTY(chassisRow.length);
        const numAvail = chassisRow.filter((row) => row.location != null && row.location != '---').length;
        setAvailQTY(numAvail);
    }, [chassisRow]);

    /** Define ChassisStatus Datagrid Fields. */
    const chassis_col = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center', hide: true },
        {
            field: 'name',
            headerName: 'Chassis#',
            width: 130,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'location',
            headerName: 'Storage Location',
            width: 200,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['---', '1st Yard - SouthWest', '2nd Yard - Insight'],
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params.row.location || '---',
            valueFormatter: (params) => (params.value === '---' ? null : params.value),
        },
        {
            field: 'container',
            headerName: 'Last Assigned CNTR#',
            width: 160,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'remark',
            headerName: 'Remark',
            width: 630,
            editable: true,
            align: 'center',
            headerAlign: 'center',
        },
    ];

    /** Handle to close a dialog for chassis status. */
    const handleClickClose = () => {
        setChassisOpen(false);
    };

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow };
        apiRequest('schedule', 'chassis', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setChassisLocation(response.body.chassis_location);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg(error.response.message);
            });
        setSnackbarOpen(true);
        return updatedRow;
    };

    return (
        <>
            <Dialog maxWidth="lg" fullWidth={true} open={chassisOpen} onClose={handleClickClose}>
                <Box sx={{ height: 620 }}>
                    <AppBar sx={{ bgcolor: '#00647e', position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClickClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, fontWeight: 'bold' }} variant="h6">
                                Chassis Status
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box
                        sx={{
                            display: 'flex',
                            m: 1,
                            color: 'text.secondary',
                        }}
                    >
                        <Typography variant="h6" component="h6" sx={{ mx: 1 }}>
                            All : {chassisQTY}
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography variant="h6" component="h6" sx={{ color: '#a7c957', mx: 1 }}>
                            Avaliable : {availQTY}
                        </Typography>
                    </Box>
                    <DataGridPremium
                        rows={chassisRow}
                        columns={chassis_col}
                        editMode="row"
                        processRowUpdate={processRowUpdate}
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        sx={{
                            height: 500,
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                        getRowHeight={() => 'auto'}
                    />
                </Box>
            </Dialog>
        </>
    );
}
