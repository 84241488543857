import * as React from 'react';
import { useState, forwardRef, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Dialog, Slide, Toolbar, Typography, IconButton, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

/** Material UI Icons. */
import CloseIcon from '@mui/icons-material/Close';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

/** Drag n Drop Library. */
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

/** Internal Components. */
import { fieldStyle } from '../../../theme/Styles';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';

import dayjs from 'dayjs';

/** Showing dialog effect. */
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function HistoryTimeline(props) {
    /** Get props from App.js. */
    const { userId } = props;
    /** Get props from History.js. */
    const { driver, timelineOpen, setTimelineOpen } = props;

    /** State for object containing all the data related to D&D.*/
    const [taskStatusCol, setTaskStatusCol] = useState({});
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [timelineData, setTimelineData] = useState([]);

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Date & Time format for datagrid. */
    const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const time_format = { hour: '2-digit', minute: '2-digit' };

    /** Handle to close History dialog.  */
    const handleCloseTimeline = () => {
        setTimelineOpen(false);
    };

    const handleTimeLine = () => {
        apiRequest('history', 'timeline', userId, selectedDate)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setTimelineData(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });
    };

    /**
     * Update taskStatusCol based on allHistoryRow.
     */
    useEffect(() => {
        //Filter movement Object.
        const movements = [...timelineData]
            .map((item) => {
                return item.movement;
            })
            .flat();
        // Filter movements based on selectedDate and exclude Status:'Canceled'.
        const filtered_movement = movements.filter((item) => {
            const local_delivieryDate = new Date(item.deliveryDate).toLocaleDateString('en-US', date_format);
            const selected_date = new Date(selectedDate).toLocaleDateString('en-US', date_format);
            return local_delivieryDate === selected_date && !item.movementstatus.includes('Canceled');
        });

        // Filter movements into unallocated and allocated based on driver_id.
        const filtered_movements_unallocated = [...filtered_movement].filter((item) => item.driver_id === null);
        const filtered_movements_allocated = [...filtered_movement].filter((item) => item.driver_id !== null);

        // Create a new taskStatusCol structure.
        const new_taskStatusCol = {
            movement: {
                name:
                    selectedDate.format('YYYY.MM.DD') === dayjs(new Date()).format('YYYY.MM.DD')
                        ? "TODAY'S MOVEMENT"
                        : selectedDate.format('YYYY.MM.DD'),
                driver_id: null,
                items: [...filtered_movements_unallocated],
            },
        };

        // Populate taskStatusCol with data for each driver.
        const field_data = [...driver].map((item, idx) => {
            const driverKey = `driver${idx}`;
            return (new_taskStatusCol[driverKey] = {
                name: `${item.first_name} ${item.last_name}`,
                driver_id: item.id,
                items: [...filtered_movements_allocated]
                    .filter((el) => el.driver_id === item.id)
                    .sort((a, b) => a.order_by - b.order_by),
            });
        });

        // Update the taskStatusCol state.
        setTaskStatusCol({
            ...new_taskStatusCol,
        });
    }, [timelineData]);

    return (
        <Dialog fullScreen open={timelineOpen} onClose={handleCloseTimeline} TransitionComponent={Transition}>
            <Box>
                <Toolbar sx={{ bgcolor: '#00647e', color: 'white', position: 'relative' }}>
                    <IconButton edge="start" color="inherit" onClick={handleCloseTimeline} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, fontWeight: 'bold' }} variant="h6" component="div">
                        Timeline History
                    </Typography>
                </Toolbar>
                <Box sx={{ mt: 3, ml: 23 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            id="seleted_date"
                            name="seleted_date"
                            label="Select Date"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    required: true,
                                },
                            }}
                            onChange={setSelectedDate}
                        />
                    </LocalizationProvider>
                    <Button
                        onClick={handleTimeLine}
                        sx={{
                            bgcolor: '#00657e',
                            color: 'white',
                            borderRadius: 5,
                            ml: 2,
                            px: 2,
                            ':hover': {
                                backgroundColor: '#003a52',
                                color: 'white',
                            },
                        }}
                    >
                        Search
                    </Button>
                </Box>
                <DragDropContext>
                    <Box name="outer" sx={{ width: '80%', mx: 'auto', my: 5 }}>
                        {Object.entries(taskStatusCol).map(([columnId, column], index) => {
                            return (
                                <div key={columnId}>
                                    <div key={columnId}>
                                        <Typography sx={{ display: 'inline', fontWeight: '500', color: '#003a54' }}>
                                            {column.name}
                                        </Typography>
                                        <div style={{ margin: 8 }}>
                                            <Droppable droppableId={columnId} key={columnId} direction={'horizontal'}>
                                                {(provided, snapshot) => {
                                                    return (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            key={columnId}
                                                            style={{
                                                                ...fieldStyle.droppable.driver,
                                                                background: snapshot.isDraggingOver
                                                                    ? '#e2eafc'
                                                                    : '#edf2f4',
                                                            }}
                                                        >
                                                            {column.items?.map((item, index) => {
                                                                return (
                                                                    <Draggable
                                                                        key={item.id.toString()}
                                                                        draggableId={item.id.toString()}
                                                                        index={index}
                                                                    >
                                                                        {(provided, snapshot) => {
                                                                            return (
                                                                                <Box
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    key={item.id.toString()}
                                                                                    style={
                                                                                        item.movementstatus ==
                                                                                            'Completed' ||
                                                                                        item.movementstatus ==
                                                                                            'Street Turned'
                                                                                            ? {
                                                                                                  ...fieldStyle
                                                                                                      .draggable
                                                                                                      .completed,
                                                                                                  ...provided
                                                                                                      .draggableProps
                                                                                                      .style,
                                                                                              }
                                                                                            : item.movementstatus ==
                                                                                              'Failed'
                                                                                            ? {
                                                                                                  ...fieldStyle
                                                                                                      .draggable.failed,
                                                                                                  ...provided
                                                                                                      .draggableProps
                                                                                                      .style,
                                                                                              }
                                                                                            : item.type === 'Load'
                                                                                            ? {
                                                                                                  ...fieldStyle
                                                                                                      .draggable.load,
                                                                                                  ...provided
                                                                                                      .draggableProps
                                                                                                      .style,
                                                                                              }
                                                                                            : {
                                                                                                  ...fieldStyle
                                                                                                      .draggable.empty,
                                                                                                  ...provided
                                                                                                      .draggableProps
                                                                                                      .style,
                                                                                              }
                                                                                    }
                                                                                >
                                                                                    <Box style={fieldStyle.chip}>
                                                                                        {item.type}
                                                                                    </Box>
                                                                                    <Box style={fieldStyle.chip}>
                                                                                        {item.method}
                                                                                    </Box>
                                                                                    <Box style={fieldStyle.chip}>
                                                                                        {
                                                                                            new Date(item.deliveryDate)
                                                                                                .toLocaleDateString(
                                                                                                    'en-US',
                                                                                                    time_format
                                                                                                )
                                                                                                .split(', ')[1]
                                                                                        }
                                                                                    </Box>
                                                                                    <br />
                                                                                    <Box
                                                                                        sx={{
                                                                                            fontSize: '0.7rem',
                                                                                        }}
                                                                                    >
                                                                                        {item.container}
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Typography
                                                                                            sx={{
                                                                                                display: 'inline',
                                                                                                verticalAlign: 'middle',
                                                                                                fontSize: '0.8rem',
                                                                                                fontWeight: 'bold',
                                                                                            }}
                                                                                        >
                                                                                            {item.pickup}
                                                                                            <DoubleArrowIcon
                                                                                                sx={{
                                                                                                    verticalAlign:
                                                                                                        'middle',
                                                                                                    fontSize: '1rem',
                                                                                                }}
                                                                                            />

                                                                                            {item.delivery}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: '0.7rem',
                                                                                            fontWeight: 'medium',
                                                                                            color: '#6c757d',
                                                                                            p: 0,
                                                                                            m: 0,
                                                                                        }}
                                                                                    >
                                                                                        {item.chassis_name != null
                                                                                            ? item.chassis_name
                                                                                            : ''}
                                                                                    </Typography>
                                                                                </Box>
                                                                            );
                                                                        }}
                                                                    </Draggable>
                                                                );
                                                            })}
                                                            {provided.placeholder}
                                                        </div>
                                                    );
                                                }}
                                            </Droppable>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Box>
                </DragDropContext>
            </Box>
            <CustomSnackbar
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                snackMsg={snackMsg}
                snackSeverity={snackSeverity}
            />
        </Dialog>
    );
}
