import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Stack,
    Box,
    Typography,
    TextField,
    Autocomplete,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

/** Material UI Icons. */
import AddIcon from '@mui/icons-material/Add';

/** Internal Components. */
import { customized_style } from '../../../../theme/Styles';

import dayjs from 'dayjs';

export default function NewMoveInput(props) {
    /** Get props from Schedule.js */
    const { locationOptions, chassis } = props;
    /** Get props from NewContainer.js.  */
    const { tempMoveRow, setTempMoveRow, containerNum } = props;
    /** Get props from NewMoveDialog.js.  */
    const { setSaveBtnDisable } = props;

    /** New movement input state (moveDate/pickup/delivery/type/method/chassis).  */
    const [moveDate, setMoveDate] = useState(null);
    const [pickUp, setPickUp] = useState(null);
    const [delivery, setDelivery] = useState(null);
    const [moveType, setMoveType] = useState('');
    const [moveMethod, setMoveMethod] = useState('');
    const [chaassisNum, setChassisNum] = useState('');

    /** Handler to change value (pickup/delivery/type/method). */
    const selectPickupChange = (event, newValue) => {
        event.preventDefault();
        setPickUp(newValue);
    };
    const selectDeliveryChange = (event, newValue) => {
        event.preventDefault();
        setDelivery(newValue);
    };
    const selectTypeChange = (event) => {
        event.preventDefault();
        setMoveType(event.target.value);
    };
    const selectMethodChange = (event) => {
        event.preventDefault();
        setMoveMethod(event.target.value);
    };
    const selectChassisNum = (event) => {
        event.preventDefault();
        setChassisNum(event.target.value);
    };

    const today = new Date();
    const available_date = new Date();
    available_date.setDate(today.getDate() - 3);

    const handleClear = () => {
        setMoveDate(null);
        setPickUp(null);
        setDelivery(null);
        setMoveType('');
        setMoveMethod('');
        setChassisNum('');
    };

    /** Receiving form data and adding a new row into Datagrid. */
    const addNewMovement = (event) => {
        event.preventDefault(); // To prevent Datagrid re-rendering.
        const form_data = new FormData(event.target);
        const last_id = tempMoveRow.length === 0 ? 0 : tempMoveRow[tempMoveRow.length - 1].id;

        // Capitalize the extracted string.
        function capitalizeFirstLetter(str) {
            return str.replace(/^\w/, (c) => c.toUpperCase());
        }
        const capitalizedTypeValue = capitalizeFirstLetter(form_data.get('move_type'));
        const capitalizedMethodValue = capitalizeFirstLetter(form_data.get('move_method'));

        // Default: In Progress & true
        const new_data = {
            id: parseInt(last_id) + 1,
            move_date: moveDate.toJSON(),
            pickup: pickUp.id,
            delivery_to: delivery.id,
            type: capitalizedTypeValue,
            method: capitalizedMethodValue,
            chassis_id: chaassisNum,
            movementstatus: 'In Progress',
            container: containerNum,
            isNew: true,
        };

        const new_Movement = [...tempMoveRow, new_data];
        setTempMoveRow(new_Movement);
        setSaveBtnDisable(false);
    };

    const [transformedOptions, setTransformedOptions] = useState([]);
    useEffect(() => {
        const transformedOptions = locationOptions.map((option) => ({
            id: option.id,
            label: option.name,
        }));
        setTransformedOptions(transformedOptions);
    }, [locationOptions]);

    return (
        <Box component="form" onSubmit={addNewMovement}>
            <Typography sx={{ color: '#00647e', fontWeight: 'bold', display: 'inline-block', mb: 1.5 }}>
                New Move
            </Typography>
            <FormControl size="small" sx={{ width: '100%', mb: 1.5 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        id="move_date"
                        name="move_date"
                        label="Move Date"
                        value={moveDate}
                        minDate={dayjs(available_date)}
                        slotProps={{
                            textField: {
                                size: 'small',
                                required: true,
                                InputProps: {
                                    style: { fontSize: '14px' },
                                },
                            },
                        }}
                        onChange={(newValue) => setMoveDate(newValue)}
                    />
                </LocalizationProvider>
            </FormControl>
            <FormControl size="small" sx={{ width: '100%', mb: 1.5 }}>
                <Autocomplete
                    disablePortal
                    size="small"
                    options={transformedOptions}
                    value={pickUp}
                    onChange={selectPickupChange}
                    required
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Pick Up"
                            required
                            InputProps={{
                                ...params.InputProps,
                                style: { fontSize: '14px' },
                            }}
                        />
                    )}
                    renderOption={(optionProps, option) => (
                        <Box component="li" {...optionProps} sx={{ fontSize: '14px' }}>
                            {option.label}
                        </Box>
                    )}
                    ListboxProps={{
                        sx: { maxHeight: 200 },
                    }}
                />
            </FormControl>
            <FormControl size="small" sx={{ width: '100%', mb: 1.5 }}>
                <Autocomplete
                    disablePortal
                    size="small"
                    options={transformedOptions}
                    value={delivery}
                    onChange={selectDeliveryChange}
                    required
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Delivery"
                            required
                            InputProps={{
                                ...params.InputProps,
                                style: { fontSize: '14px' }, // fontSize 조정
                            }}
                        />
                    )}
                    renderOption={(optionProps, option) => (
                        <Box component="li" {...optionProps} sx={{ fontSize: '14px' }}>
                            {option.label}
                        </Box>
                    )}
                    ListboxProps={{
                        sx: { maxHeight: 200 },
                    }}
                />
            </FormControl>
            <FormControl size="small" sx={{ width: '100%', mb: 1.5 }}>
                <InputLabel id="move_type" required>
                    Type
                </InputLabel>
                <Select
                    id="move_type"
                    label="Type"
                    value={moveType}
                    onChange={selectTypeChange}
                    name="move_type"
                    required
                >
                    <MenuItem value="load" sx={{ fontSize: '14px' }}>
                        Load
                    </MenuItem>
                    <MenuItem value="empty" sx={{ fontSize: '14px' }}>
                        Empty
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: '100%', mb: 1.5 }}>
                <InputLabel id="move_method" required>
                    Method
                </InputLabel>
                <Select
                    id="move_method"
                    label="Method"
                    value={moveMethod}
                    onChange={selectMethodChange}
                    name="move_method"
                    required
                >
                    <MenuItem value="live" sx={{ fontSize: '14px' }}>
                        Live
                    </MenuItem>
                    <MenuItem value="drop" sx={{ fontSize: '14px' }}>
                        Drop
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: '100%', mb: 1.5 }}>
                <InputLabel id="chassis">Chassis</InputLabel>
                <Select
                    id="chassis"
                    label="Chassis Number"
                    value={chaassisNum}
                    onChange={selectChassisNum}
                    name="chassis"
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                maxHeight: 250,
                            },
                        },
                    }}
                >
                    <MenuItem value="">N/A</MenuItem>
                    {chassis.map((num) => (
                        <MenuItem key={num.id} value={num.id} sx={{ fontSize: '14px' }}>
                            {num.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Stack direction="row" justifyContent="center">
                <Button type="submit" sx={{ ...customized_style.itemButtons_add, width: '40%' }}>
                    <AddIcon />
                    Add
                </Button>
                <Button
                    sx={{
                        ...customized_style.itemButtons_add,
                        width: '40%',
                        ml: 3,
                        bgcolor: 'white',
                        border: '1px solid #00647e',
                        color: '#00647e',
                    }}
                    onClick={handleClear}
                >
                    clear
                </Button>
            </Stack>
        </Box>
    );
}
